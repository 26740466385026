import React from 'react';
import HoverIntent from 'react-hoverintent';
import { Link } from 'react-router-dom';
import { Price } from '../../../../../../UI/Price/Price';
import { ProductExtraVerbiage } from '../../../../../Shared/Content/ProductDetail/ProductExtraVerbiage/ProductExtraVerbiage';
import { SharedCatalogEntryTile } from '../../../../../Shared/Content/Layouts/Widgets/CatalogEntryList/CatalogEntryTile';
import LazyLoad from '../../../../../../UI/LazyLoad/LazyLoad';
import Slider from '../../../../../../../customModules/react-slick';

export class CatalogEntryListTile extends SharedCatalogEntryTile {
	constructor(props) {
		super(props);
		this.state = {};
		this.state.hovered = false;
		this.state.imageHovered = false;
		this.state.keyboardFocused = false;
		this.state.swatchAltImages = JSON.parse(JSON.stringify(this.props.thisProduct.swatches || []));
		this.state.swatchColorUrls = [];
		if (this.props.thisProduct && this.props.thisProduct.dil_react_swatch && this.props.thisProduct.dil_react_swatch.trim().length > 0
		&& this.props.thisProduct.dil_react_swatch.indexOf(',') > -1 )
			this.state.swatchColorUrls = JSON.parse(JSON.stringify(this.props.thisProduct.dil_react_swatch.split(',')[0].split('|')));
		if(this.props.thisProduct && this.props.thisProduct.swatchImages && this.props.thisProduct.dil_react_swatch && this.props.thisProduct.dil_react_swatch.trim().length > 0){
			this.state.swatchColorUrls = [];
			var reactSwatch = this.props.thisProduct.dil_react_swatch.split(',')[0].split("|");
			for(var i = 0; i < this.props.thisProduct.swatchImages.length; i++){
				for(var j = 0; j < reactSwatch.length; j++){
					if(reactSwatch[j].indexOf(this.props.thisProduct.swatchImages[i]) > -1){
						this.state.swatchColorUrls.push(reactSwatch[j]);
					}
				}
			}
		}
		this.state.selectedImageIndex = 0;
		this.state.selectedSwatchGroup = this.props.thisProduct.defaultAlt;

		for (var i = 0; i < this.state.swatchAltImages.length; i++) {
			var thisSwatchGroup = this.state.swatchAltImages[i];
			if (thisSwatchGroup.indexOf(this.props.thisProduct.fullImage) > -1) {
				this.state.selectedSwatchIndex = i;
				this.state.selectedSwatchGroup = thisSwatchGroup;
			}
		}
		if (typeof window != 'undefined' && window.injectedAltImageFunctionNav) {
			this.state.selectedSwatchGroup = window.injectedAltImageFunctionNav(this.state.selectedSwatchGroup);
		}
		this.handleMouseEnterTile = this.handleMouseEnterTile.bind(this);
		this.handleMouseLeaveTile = this.handleMouseLeaveTile.bind(this);
		this.handleMouseEnterImage = this.handleMouseEnterImage.bind(this);
		this.handleMouseLeaveImage = this.handleMouseLeaveImage.bind(this);
		this.goToNextImage = this.goToNextImage.bind(this);
		this.handleKeyboardFocus = this.handleKeyboardFocus.bind(this);
		this.handleKeyboardEnterImage = this.handleKeyboardEnterImage.bind(this);
		this.handleKeyboardLeaveImage = this.handleKeyboardLeaveImage.bind(this);
		this.swatchClick = this.swatchClick.bind(this);
	}
	componentWillReceiveProps(nextProps) {
		this.state.selectedSwatchGroup = nextProps.thisProduct.defaultAlt;
		this.state.swatchAltImages = JSON.parse(JSON.stringify(nextProps.thisProduct.swatches || []));
		for (var i = 0; i < this.state.swatchAltImages.length; i++) {
			var thisSwatchGroup = this.state.swatchAltImages[i];
			if (thisSwatchGroup.indexOf(nextProps.thisProduct.fullImage) > -1) {
				this.state.selectedSwatchIndex = i;
				this.state.selectedSwatchGroup = thisSwatchGroup;
			}
		}
		if (nextProps.thisProduct && nextProps.thisProduct.dil_react_swatch && nextProps.thisProduct.dil_react_swatch.trim().length > 0
		&& nextProps.thisProduct.dil_react_swatch.indexOf(',') > -1 )
			this.state.swatchColorUrls = JSON.parse(JSON.stringify(this.props.thisProduct.dil_react_swatch.split(',')[0].split('|')));
		
		if(nextProps.thisProduct && nextProps.thisProduct.swatchImages && nextProps.thisProduct.dil_react_swatch && nextProps.thisProduct.dil_react_swatch.trim().length > 0){
			this.state.swatchColorUrls = [];
			var reactSwatch2 = nextProps.thisProduct.dil_react_swatch.split(',')[0].split("|");
			for(var i = 0; i < nextProps.thisProduct.swatchImages.length; i++){
				for(var j = 0; j < reactSwatch2.length; j++){
					if(reactSwatch2[j].indexOf(nextProps.thisProduct.swatchImages[i]) > -1){
						this.state.swatchColorUrls.push(reactSwatch2[j]);
					}
				}
			}
		}
		if (typeof window != 'undefined' && window.injectedAltImageFunctionNav) {
			this.state.selectedSwatchGroup = window.injectedAltImageFunctionNav(this.state.selectedSwatchGroup);
		}
	}
	generateReviewSnippet(thisProduct) {
		var numOfStars = thisProduct.numStars;
		var returnElements = [];
		if (numOfStars > 0) {
			for (var iStar = 0; iStar <= 4; iStar++) {
				if (Math.floor(numOfStars) > iStar) {
					returnElements.push(
						<i key={iStar} className='icon star-full'>
							<span className='sr-only'>{`Rated ${numOfStars} out of 5 stars`}</span>
						</i>
					);
				} else if (numOfStars > iStar) {
					returnElements.push(
						<i key={iStar} className='icon star-half'>
							<span className='sr-only'>{`Rated ${numOfStars} out of 5 stars`}</span>
						</i>
					);
				} else {
					returnElements.push(
						<i key={iStar} className='icon star-empty'>
							<span className='sr-only'>{`Rated ${numOfStars} out of 5 stars`}</span>
						</i>
					);
				}
			}
			returnElements.push(
				<span className='reviewCount' key={++iStar}>
					({thisProduct.numReviews})
				</span>
			);
		}
		return returnElements;
	}

	swatchClick(event) {
		var swatchIndex = event.target.getAttribute('data-swatchindex') * 1;
		var newSwatches = this.state.swatchAltImages[swatchIndex];

		if (typeof window != 'undefined' && window.injectedAltImageFunctionNav) {
			newSwatches = window.injectedAltImageFunctionNav(newSwatches);
		}
		var newNumAlts = newSwatches.length;
		var currentAltIndex = this.state.currentAltIndex;
		if (currentAltIndex >= newNumAlts) {
			//we have to slide after the slider renders...
			this.state.needToGoToZero = true;
		} else {
			//we can go ahead and slide.
			if (this.altSlider) {
				this.altSlider.slickGoTo(0, true);
			}
		}
		this.setState({
			selectedSwatchIndex: swatchIndex,
			selectedSwatchGroup: newSwatches,
			currentAltIndex: 0,
		});
	}

	// hover functions for rotating alts 
	handleMouseEnterTile() {
		this.setState({ hovered: true });
	}
	handleMouseLeaveTile() {
		this.setState({ hovered: false });
	}
	handleMouseEnterImage() {
		if (!this.state.keyboardFocused) {
			this.setState({ imageHovered: true, selectedImageIndex: 0 });
			this.state.timeout = setTimeout(this.goToNextImage, 1000);
		}
	}
	handleMouseLeaveImage() {
		this.setState({ imageHovered: false, selectedImageIndex: 0 });
		clearTimeout(this.state.timeout);
	}
	goToNextImage() {
		
		if (this.state.imageHovered && this.state.selectedSwatchGroup) {
			if (this.state.selectedSwatchGroup.length - 1 > this.state.selectedImageIndex) {
				this.setState({
					selectedImageIndex: this.state.selectedImageIndex + 1,
				});
			} else {
				this.setState({ selectedImageIndex: 0 });
			}
			this.state.timeout = setTimeout(this.goToNextImage, 1000);
		}
	}
	// ADA keyboard controls for rotating alts and quickview acessability
	handleKeyboardFocus(event) {
		if (event.key == 'Tab' && !event.shiftKey && !this.state.hovered) {
			this.setState({ hovered: true });
		} else {
			if (event.key == 'Tab' && this.state.hovered) {
				this.setState({ hovered: false, keyboardFocused: false });
			}
		}
	}
	handleKeyboardEnterImage() {
		if (!this.state.imageHovered) {
			this.setState({ imageHovered: true, selectedImageIndex: 0, keyboardFocused: true });
			this.state.timeout = setTimeout(this.goToNextImage, 1000);
		}
	}
	handleKeyboardLeaveImage() {
		this.setState({ imageHovered: false, selectedImageIndex: 0, keyboardFocused: false });
		clearTimeout(this.state.timeout);
	}

	componentWillUnmount() {
		if (this.state.timeout) {
			clearTimeout(this.state.timeout);
		}
	}

	componentDidUpdate() {
		//setTimeout bad.
		//the "right" way of fixing this would be to either make the flipping of the slider
		//  dependent on the render of the images or to move the render after zeroing
		//  the slider.  This made the experience laggy for the 99.9% of situations
		//  just to fix this one edge case.  A little bit of jank to keep a lot of jank off the site...
		if (this.state.needToGoToZero && this.altSlider) {
			setTimeout(() => {
				this.state.needToGoToZero = false;
				if (this.state.currentAltIndex == 0) {
					this.altSlider.slickGoTo(0, true);
				} else {
					setTimeout(() => {
						if (this.state.currentAltIndex == 0) {
							this.altSlider.slickGoTo(0, true);
						}
					}, 250);
				}
			}, 750);
		}
	}
	render() {
		var thisProduct = this.props.thisProduct;
		var actualBreadCrumb = this.props.actualBreadCrumb;
		var settings = {
			dots: true,
			infinite: false,
			vertical: false,
			speed: 500,
			slidesToShow: 1,
			swipeToSlide: true,
			swipe: true,
			lazyLoad: true,
			arrows: false,
			afterChange: (index) => {
				if (!this.state.needToGoToZero) {
					this.state.currentAltIndex = index;
				}
			},
		};
		var altImagesToUse = this.state.selectedSwatchGroup;

		if (typeof window != 'undefined' && window.injectedAltImageFunctionNav) {
			altImagesToUse = window.injectedAltImageFunctionNav(altImagesToUse);
		}

		if (altImagesToUse.length > 7) {
			altImagesToUse.length = 7;
		}
		return (
			<div
				className={`${this.props.fromOutfit ? 'col-sm-3' : 'col-sm-4'} result-tile`}
				aria-label='product'
				onMouseOver={this.handleMouseEnterTile}
				onMouseLeave={this.handleMouseLeaveTile}
			>
				{this.props.siteFormat == 'desktop' ? (
					<HoverIntent onMouseOver={this.handleMouseEnterImage} onMouseOut={this.handleMouseLeaveImage}>
						<div className='result-tile-above'>
							<Link
								className='d-block tileImgLinkWrapper'
								to={this.generateProductUrl(thisProduct, false, actualBreadCrumb)}
								onFocus={this.handleKeyboardEnterImage}
								onBlur={this.handleKeyboardLeaveImage}
								onKeyDown={this.handleKeyboardFocus}
							>
								<Choose>
									<When test={this.props.productIndex < 9 || this.props.lastTile}>
										<img
											src={`https://dimg.dillards.com/is/image/DillardsZoom/nav2/${thisProduct.nameForURL}/${
												this.state.selectedSwatchGroup && this.state.selectedSwatchGroup[this.state.selectedImageIndex]
													? this.state.selectedSwatchGroup && this.state.selectedSwatchGroup[this.state.selectedImageIndex]
													: thisProduct.fullImage
											}.jpg`}
											alt={thisProduct.originalName}
											width='414'
											height='480'
											style={{ maxWidth: '100%', height: 'auto' }}
											className={`${!this.props.lastTile ? 'NotLazyLoad' : 'LazyLoadIndicator'} ${this.props.productIndex == 0 ? 'is-visible' : ''}`}
											aria-label={`Product image-${this.state.selectedImageIndex + 1}`}
										/>
									</When>
									<Otherwise>
										<LazyLoad offsetVertical={1140}>
											<img
												width='414'
												height='480'
												style={{ maxWidth: '100%', height: 'auto' }}
												src={`https://dimg.dillards.com/is/image/DillardsZoom/nav2/${thisProduct.nameForURL}/${
													this.state.selectedSwatchGroup && this.state.selectedSwatchGroup[this.state.selectedImageIndex]
														? this.state.selectedSwatchGroup && this.state.selectedSwatchGroup[this.state.selectedImageIndex]
														: thisProduct.fullImage
												}.jpg`}
												alt={thisProduct.originalName}
											/>
										</LazyLoad>
									</Otherwise>
								</Choose>
							</Link>
							<If test={this.state.hovered && !thisProduct.noQV && !thisProduct.chanelTile && !this.props.fromOutfit && this.props.siteFormat !== 'tablet'}>
								<span className='quickviewHover'>
									<Link to={this.generateProductUrl(thisProduct, true, actualBreadCrumb)} onKeyDown={this.handleKeyboardFocus}>
										Quick View
									</Link>
								</span>
							</If>
						</div>
					</HoverIntent>
				) : (
					<div className='result-tile-above'>
						<Slider ref={(slider) => (this.altSlider = slider)} {...settings}>
							{(altImagesToUse || []).map((altImage, index) => (
								<Link className='d-block tileImgLinkWrapper' to={this.generateProductUrl(thisProduct, false, actualBreadCrumb)} key={altImage + index}>
									<Choose>
										<When test={this.props.productIndex < 9}>
											<img
												width='414'
												height='480'
												style={{ maxWidth: '100%', height: 'auto' }}
												src={`https://dimg.dillards.com/is/image/DillardsZoom/nav2/${thisProduct.nameForURL}/${altImage}.jpg`}
												alt={thisProduct.originalName}
											/>
										</When>
										<Otherwise>
											<LazyLoad offsetVertical={849}>
												<img
													width='414'
													height='480'
													style={{ maxWidth: '100%', height: 'auto' }}
													src={`https://dimg.dillards.com/is/image/DillardsZoom/nav2/${thisProduct.nameForURL}/${altImage}.jpg`}
													alt={thisProduct.originalName}
												/>
											</LazyLoad>
										</Otherwise>
									</Choose>
								</Link>
							))}
						</Slider>
					</div>
				)}
				<div className='result-tile-below'>
					<If test={this.state.swatchAltImages && this.state.swatchAltImages.length > 0}>
						<div className={`swatches swatches-${thisProduct.catentryId}`}>
							<ForEach var='thisSwatchAlts' items={this.state.swatchAltImages} index='index'>
								<If
									test={
										thisSwatchAlts &&
										Array.isArray(thisSwatchAlts) &&
										thisSwatchAlts.length > 0 &&
										this.state.swatchColorUrls &&
										Array.isArray(this.state.swatchColorUrls) &&
										this.state.swatchColorUrls.length > 0 &&
										this.state.swatchColorUrls[index] &&
										this.state.swatchColorUrls[index].trim().length > 0 &&
										this.state.swatchColorUrls.length >= this.state.swatchAltImages.length &&
										this.state.swatchColorUrls[index].split(':')[0].localeCompare(thisSwatchAlts[0])
									}
								>
									<button
										onClick={this.swatchClick}
										data-swatchindex={index}
										className={`btn-tertiary p-0 mr-3 swatches-btn`}
										key={thisSwatchAlts[0] + ' ' + index}
										rel='nofollow'
										aria-current={this.state.selectedSwatchIndex == index ? 'true' : 'false'}
									>
										<Choose>
											<When test={this.props.productIndex < 9}>
												<img
													onClick={this.swatchClick}
													className={`search__swatches__img ${index == this.state.selectedSwatchIndex ? 'search__swatches__img--selected' : ''}`}
													data-swatchindex={index}
													src={`//dimg.dillards.com/is/image/DillardsZoom/${this.state.swatchColorUrls[index].split(':')[0]}`}
													alt={`Color Swatch - ${
														this.state.swatchColorUrls[index].includes(':') && this.state.swatchColorUrls[index].split(':').length > 1
															? this.state.swatchColorUrls[index].split(':')[1]
															: 'color swatch'
													}`}
												/>
											</When>
											<Otherwise>
												<LazyLoad offsetVertical={this.props.siteFormat == 'desktop' ? 1520 : 1310}>
													<img
														onClick={this.swatchClick}
														className={`search__swatches__img ${index == this.state.selectedSwatchIndex ? 'search__swatches__img--selected' : ''}`}
														data-swatchindex={index}
														src={`//dimg.dillards.com/is/image/DillardsZoom/${this.state.swatchColorUrls[index].split(':')[0]}`}
														alt={`Color Swatch - ${
															this.state.swatchColorUrls[index].includes(':') && this.state.swatchColorUrls[index].split(':').length > 1
																? this.state.swatchColorUrls[index].split(':')[1]
																: 'color swatch'
														}`}
													/>
												</LazyLoad>
											</Otherwise>
										</Choose>
									</button>
								</If>
							</ForEach>
						</div>
					</If>
 					<If test={thisProduct.numSwatches && thisProduct.numSwatches > 5}>
					<Link className='d-block tileImgLinkWrapper' to={this.generateProductUrl(thisProduct, false, actualBreadCrumb)}>
							<div className='seeAllColors'>
								See all {thisProduct.numSwatches} colors {'>'}
							</div>
						</Link>
						</If>
						<h3 className='productTitle'>
						<Link className='d-block tileImgLinkWrapper' to={this.generateProductUrl(thisProduct, false, actualBreadCrumb)}>
							<span className='brandName'>{thisProduct.brand}</span>
						</Link>
						<Link className='d-block tileImgLinkWrapper' to={this.generateProductUrl(thisProduct, false, actualBreadCrumb)}>
							<span data-part-number={thisProduct.partNumber} className='productName'>
								{thisProduct.name.split('#double;').join('"')}
							</span>
						</Link>
						</h3>
					<Link className='d-block tileImgLinkWrapper' to={this.generateProductUrl(thisProduct, false, actualBreadCrumb)}>
						<Price pricing={thisProduct.pricing}></Price>
					</Link>
					<Link className='d-block tileImgLinkWrapper' to={this.generateProductUrl(thisProduct, false, actualBreadCrumb)}>						 
						<ProductExtraVerbiage navTile={true} contentData={this.props.thisProduct} passedAppFunctions={this.props.passedAppFunctions}></ProductExtraVerbiage>
					</Link>
  					<If test={thisProduct.numStars && thisProduct.numStars > 0}>
	 					<Link className='d-block tileImgLinkWrapper' to={this.generateProductUrl(thisProduct, false, actualBreadCrumb)}>						 
						{this.generateReviewSnippet(this.props.thisProduct)}
					</Link>
					</If>
				</div>
			</div>
		);
	}
}

export default CatalogEntryListTile;
