import React from 'react';
import { Link } from "react-router-dom";

export class ShopNewArrivals extends React.Component {
    generateLinkForFacet(thisFacetValue) {
        var returnLink = this.props.passedAppFunctions.routeInfo.location.pathname.split('?')[0];
        var search = this.props.passedAppFunctions.routeInfo.location.search || '';
        if (this.props.passedAppFunctions.routeInfo.location.pathname.indexOf('?') > -1) {
            search = '?' + this.props.passedAppFunctions.routeInfo.location.pathname.split('?').splice(1).join('?');
        }
        if (this.props.resultListIsPresent) {
            return this.props.passedAppFunctions.updateUrlWithParams(returnLink + search, { pageNumber: false, facet: thisFacetValue });
        } else {
            return this.props.passedAppFunctions.updateUrlWithParams(returnLink + search, { pageNumber: false, facet: thisFacetValue, forceFlatResults: 'forceFlatResults' });
        }
    }

    thisFacetClicked = (event) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.target.parentNode.lastChild.click();
        }
	};

    render() {
        var noFacetsJSON = true;
        var shouldShowNewArrivals = true;
        if (this.props.facetsJSON && this.props.facetsJSON['facetsList']) {
            shouldShowNewArrivals = this.props.facetsJSON['dil_newArrival'];
            noFacetsJSON = false;
        }
        return (
				<fieldset>
					<legend className='sr-only'>New Arrivals</legend>
					<ul className='facet facet-header no-style'>
						<li id='newArrivalsChkBox' className='ShopNewArrivals'>
							<div className='custom-control custom-checkbox'>
								<If test={shouldShowNewArrivals}>
									<input
										className='custom-control-input'
										type='checkbox'
										role='checkbox'
										readOnly
										aria-checked={(this.props.passedAppFunctions.getURLParamValue('facet') || '').indexOf('dil_newArrival') > -1}
										checked={(this.props.passedAppFunctions.getURLParamValue('facet') || '').indexOf('dil_newArrival') > -1}
										onKeyUp={this.thisFacetClicked}
									></input>
									<label
										className='custom-control-label-nocolor custom-control-label'
										htmlFor='newArrivalsChkBox'
										onClick={(e) => e.target.nextElementSibling.click()}
									>
										<span className='sr-only'>New Arrivals</span>
									</label>
									<Link to={this.generateLinkForFacet('dil_newArrival%3A%221%22')} manual_cm_sp={this.props.widgetData.manualCmSp} tabIndex='-1'>
										New Arrivals
									</Link>
								</If>
							</div>
						</li>
					</ul>
				</fieldset>
				);
    }
}

export default ShopNewArrivals;
