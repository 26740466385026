import React, { useState } from 'react';
import { ContentRecommendation } from './ContentRecommendation/ContentRecommendation';
import { ShopByPriceReduction } from './ShopByPriceReduction/ShopByPriceReduction';
import { BreadcrumbTrail } from './BreadcrumbTrail/BreadcrumbTrail';
import { CategoryNavigation } from './CategoryNavigation/CategoryNavigation';
import { FacetNavigation } from './FacetNavigation/FacetNavigation';
import { CatalogEntryList } from './CatalogEntryList/CatalogEntryList';
import { ShopByBrand } from './ShopByBrand/ShopByBrand';
import { SearchSummary } from './SearchSummary/SearchSummary';
import { HOneHeader } from './HOneHeader/HOneHeader';
import { FourProductCatalogEntryList } from './FourProductCatalogEntryList/FourProductCatalogEntryList';
import { StoreLocationsResultsList } from './StoreLocationsResultsList/StoreLocationsResultsList';
import { SearchForm } from './SearchForm/SearchForm';
import { SiteMapDisplay } from './SiteMapDisplay/SiteMapDisplay';
import { SpotlightSearch } from './SpotlightSearch/SpotlightSearch';
import { ChanelCategoryNavigation } from './ChanelCategoryNavigation/ChanelCategoryNavigation';
import { ChanelCatalogEntryList } from './ChanelCatalogEntryList/ChanelCatalogEntryList';
import { OutfitResults } from './OutfitResults/OutfitResults';
import { CherryList } from './CherryList/CherryList';
import { Contest } from './Contest/Contest';
import { RegistryIncentives } from '../../../../Shared/Content/Layouts/Widgets/RegistryIncentives/RegistryIncentives';
import { RegistryHeader } from '../../../../Shared/Content/Layouts/Widgets/RegistryHeader/RegistryHeader';
import { ShopNewArrivals } from './ShopNewArrivals/ShopNewArrivals';
import { ShopSale } from './ShopSale/ShopSale';
import { SplashImage } from '../../../../Shared/Content/Layouts/Widgets/SplashImage/SplashImage';
import { ProductRecommendations } from './ProductRecommendations/ProductRecommendations';
import { ProductCarousel } from '../../../../Shared/Content/Layouts/Widgets/ProductCarousel/ProductCarousel';
import { BambuserMainShow } from '../../../../Shared/Content/Layouts/Widgets/BambuserMainShow/BambuserMainShow';
import { BambuserCompletedShows } from '../../../../Shared/Content/Layouts/Widgets/BambuserCompletedShows/BambuserCompletedShows';

export function Widgets(props) {
	//const [excludeFacets, setExcludeFacets] = useState(props.excludeFacets);
	const slotData = props.slotData;
	return (
		<>
			{slotData.widgets.map((thisWidget, index) => {
				return (
					<React.Fragment key={index}>
						{
							{
								ContentRecommendation: (
									<ContentRecommendation
										slotNumber={props.slotNumber}
										widgetData={thisWidget}
										passedAppFunctions={props.passedAppFunctions}
										resultListIsPresent={props.resultListIsPresent}
									/>
								),

								BreadcrumbTrail: (
									<BreadcrumbTrail
										slotNumber={props.slotNumber}
										widgetData={thisWidget}
										passedAppFunctions={props.passedAppFunctions}
										containsBreadcrumbTrail={props.containsBreadcrumbTrail}
									/>
								),
								Contest: <Contest slotNumber={props.slotNumber} widgetData={thisWidget} passedAppFunctions={props.passedAppFunctions} />,
								CategoryNavigation: (
									<CategoryNavigation
										newArrivalsWidgetIsPresent={props.newArrivalsWidgetIsPresent}
										shopSaleWidgetIsPresent={props.shopSaleWidgetIsPresent}
										slotNumber={props.slotNumber}
										widgetData={thisWidget}
										passedAppFunctions={props.passedAppFunctions}
										facetsJSON={props.facetsJSON}
										toggleFacetMenu={props.toggleFacetMenu}
										resultListIsPresent={props.resultListIsPresent}
										excludeFacets={props.excludeFacets}
									/>
								),
								FacetNavigation: (
									<FacetNavigation
										slotNumber={props.slotNumber}
										widgetData={thisWidget}
										passedAppFunctions={props.passedAppFunctions}
										facetsJSON={props.facetsJSON}
										toggleFacetMenu={props.toggleFacetMenu}
										toggleSortMenu={props.toggleSortMenu}
										excludeFacets={props.excludeFacets}
									/>
								),
								ShopByPriceReduction: (
									<ShopByPriceReduction slotNumber={props.slotNumber} widgetData={thisWidget} passedAppFunctions={props.passedAppFunctions} />
								),
								CatalogEntryList: (
									<CatalogEntryList
										slotNumber={props.slotNumber}
										widgetData={thisWidget}
										passedAppFunctions={props.passedAppFunctions}
										showSortMenu={props.showSortMenu}
										toggleFacetMenu={props.toggleFacetMenu}
										toggleSortMenu={props.toggleSortMenu}
										actualBreadCrumb={props.actualBreadCrumb}
									/>
								),
								ShopByBrand: <ShopByBrand slotNumber={props.slotNumber} widgetData={thisWidget} passedAppFunctions={props.passedAppFunctions} />,
								SearchSummary: <SearchSummary slotNumber={props.slotNumber} widgetData={thisWidget} passedAppFunctions={props.passedAppFunctions} />,
								HOneHeader: (
									<HOneHeader
										slotNumber={props.slotNumber}
										widgetData={thisWidget}
										passedAppFunctions={props.passedAppFunctions}
										containsBreadcrumbTrail={props.containsBreadcrumbTrail}
									/>
								),
								FourProductCatalogEntryList: (
									<FourProductCatalogEntryList slotNumber={props.slotNumber} widgetData={thisWidget} passedAppFunctions={props.passedAppFunctions} />
								),
								StoreLocationsResultsList: (
									<StoreLocationsResultsList slotNumber={props.slotNumber} widgetData={thisWidget} passedAppFunctions={props.passedAppFunctions} />
								),
								SearchForm: <SearchForm slotNumber={props.slotNumber} widgetData={thisWidget} passedAppFunctions={props.passedAppFunctions} />,
								SiteMapDisplay: <SiteMapDisplay slotNumber={props.slotNumber} widgetData={thisWidget} passedAppFunctions={props.passedAppFunctions} />,
								SpotlightSearch: <SpotlightSearch slotNumber={props.slotNumber} widgetData={thisWidget} passedAppFunctions={props.passedAppFunctions} />,
								ChanelCategoryNavigation: (
									<ChanelCategoryNavigation slotNumber={props.slotNumber} widgetData={thisWidget} passedAppFunctions={props.passedAppFunctions} />
								),
								ChanelCatalogEntryList: (
									<ChanelCatalogEntryList slotNumber={props.slotNumber} widgetData={thisWidget} passedAppFunctions={props.passedAppFunctions} />
								),
								OutfitResults: <OutfitResults slotNumber={props.slotNumber} widgetData={thisWidget} passedAppFunctions={props.passedAppFunctions} />,
								CherryList: <CherryList slotNumber={props.slotNumber} widgetData={thisWidget} passedAppFunctions={props.passedAppFunctions} />,
								RegistryIncentives: <RegistryIncentives slotNumber={props.slotNumber} widgetData={thisWidget} passedAppFunctions={props.passedAppFunctions} />,
								RegistryHeader: <RegistryHeader slotNumber={props.slotNumber} widgetData={thisWidget} passedAppFunctions={props.passedAppFunctions} />,
								ShopNewArrivals: (
									<ShopNewArrivals
										slotNumber={props.slotNumber}
										widgetData={thisWidget}
										passedAppFunctions={props.passedAppFunctions}
										facetsJSON={props.facetsJSON}
										resultListIsPresent={props.resultListIsPresent}
									/>
								),
								ShopSale: (
									<ShopSale
										slotNumber={props.slotNumber}
										widgetData={thisWidget}
										passedAppFunctions={props.passedAppFunctions}
										facetsJSON={props.facetsJSON}
										resultListIsPresent={props.resultListIsPresent}
									/>
								),
								SplashImage: <SplashImage slotNumber={props.slotNumber} widgetData={thisWidget} passedAppFunctions={props.passedAppFunctions} />,
								ProductRecommendations: (
									<If test={props.passedAppFunctions.wasParms.useProductRecommendations == 'true'}>
										<ProductRecommendations
											contentData={props.contentData}
											slotNumber={props.slotNumber}
											widgetData={thisWidget}
											passedAppFunctions={props.passedAppFunctions}
										/>
									</If>
								),
								ProductCarousel: (
									<ProductCarousel
										contentData={props.contentData}
										slotNumber={props.slotNumber}
										widgetData={thisWidget}
										productsPerRow={thisWidget.productsPerRow}
										rows={thisWidget.rows}
										scrollable={thisWidget.scrollable}
										title={thisWidget.title}
										showProductTitle={thisWidget.showProductTitle}
										cmsp={thisWidget.cmsp}
										products={thisWidget.products}
										passedAppFunctions={props.passedAppFunctions}
									/>
								),
								BambuserMainShow: (
									<If test={props.passedAppFunctions.wasParms.bambuserEnabled == 'true'}>
										<BambuserMainShow slotNumber={props.slotNumber} widgetData={thisWidget} passedAppFunctions={props.passedAppFunctions} />
									</If>
								),
								BambuserCompletedShows: (
									<If test={props.passedAppFunctions.wasParms.bambuserEnabled == 'true'}>
										<BambuserCompletedShows slotNumber={props.slotNumber} widgetData={thisWidget} passedAppFunctions={props.passedAppFunctions} />
									</If>
								),
							}[thisWidget.widgetType]
						}
					</React.Fragment>
				);
			})}
		</>
	);
}
