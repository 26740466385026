import React from 'react';
import { Link } from "react-router-dom";
import { CatalogEntryListTile } from '../CatalogEntryList/CatalogEntryListTile';
import CatalogEntryListStyleHeight from '../../../../../Shared/Content/Layouts/Widgets/CatalogEntryList/CatalogEntryListStyleHeight';

export class CherryList extends React.Component {
    constructor(props){
        super(props);
        this.state = {};
    }
    render() {
        return (
            <div className='CatalogEntryList CherryList row' >
                <CatalogEntryListStyleHeight passedAppFunctions={this.props.passedAppFunctions} />
         
                    <Choose>
                        <When test={this.props.widgetData.products && this.props.widgetData.products.length > 0}>
                            <ForEach items={this.props.widgetData.products} var='thisProduct' index='productIndex' >
                                <CatalogEntryListTile lastTile={productIndex == (this.props.widgetData.products.length-1)}  passedAppFunctions={this.props.passedAppFunctions} siteFormat={this.props.passedAppFunctions.siteFormat} thisProduct={thisProduct} key={thisProduct.catentryId} productIndex={productIndex} />
                            </ForEach>
                        </When>
                        <Otherwise>
                            <div className='col-sm-12 col-sm-12 col-md-12 col-lg-12'>
                                <div className='alert alert-danger m-t-15' role='alert'>No products were found that match this criteria.</div>
                            </div>
                        </Otherwise>
                    </Choose>
                
            </div>
        )
    }
}

export default CherryList;
