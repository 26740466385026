import React from 'react';
import { Link } from "react-router-dom";
import { SharedCatalogEntryList } from '../../../../../Shared/Content/Layouts/Widgets/CatalogEntryList/CatalogEntryList';
import { Price } from '../../../../../../UI/Price/Price';


export class ChanelCatalogEntryList extends SharedCatalogEntryList {

    render() {
        var thisProduct = this.props.thisProduct;
        return (
            <div className='row'>
                <Choose>
                    <When test={this.props.widgetData.products.length > 0 && !this.props.passedAppFunctions.isInternational()} >
                        <ForEach var='thisProduct' items={this.props.widgetData.products} >
                            <ChanelCatalogEntryListTile thisProduct={thisProduct} key={thisProduct.uniqueId} passedAppFunctions={this.props.passedAppFunctions}/>
                        </ForEach>
                    </When>
                    <Otherwise>
                        <div className='col-sm-12 p-l-30 p-r-30 m-t-15'>
                            <div className='alert alert-danger nav-alert' role='alert'>No products were found that match this criteria.</div>
                        </div>
                    </Otherwise>
                </Choose>
            </div>
        ) 
    }
}

class ChanelCatalogEntryListTile extends React.Component {
    render() {
        var thisProduct = this.props.thisProduct;
        return (
            <div className='col-sm-3 chanelTile'>
                <div className='result-tile'>
                    <Link to={{pathname:`/p/${thisProduct.productKeyword}/${thisProduct.uniqueId}`, state: {useOldCategoryId:true}}}>
                        <img src={`https://dimg.dillards.com/is/image/DillardsZoom/nav2/${thisProduct.productKeyword}/${thisProduct.productImage}.jpg`} alt={thisProduct.chanelTitle + ' ' + thisProduct.productTitle + ' ' + thisProduct.productSubtitle.toUpperCase()} />
                    </Link>
                    <Link to={{pathname:`/p/${thisProduct.productKeyword}/${thisProduct.uniqueId}`, state: {useOldCategoryId:true}}}>
                        <p className='chanelTitle'>{thisProduct.chanelTitle}</p>
                    </Link>
                    <Link to={{pathname:`/p/${thisProduct.productKeyword}/${thisProduct.uniqueId}`, state: {useOldCategoryId:true}}}>
                        <p className='chanelProductTitle'>{thisProduct.productTitle}</p>
                    </Link>
                    <Link to={{pathname:`/p/${thisProduct.productKeyword}/${thisProduct.uniqueId}`, state: {useOldCategoryId:true}}}>
                         <p className='chanelSubTitle'>{thisProduct.productSubtitle}</p>
                    </Link>
                    <Link to={{pathname:`/p/${thisProduct.productKeyword}/${thisProduct.uniqueId}`, state: {useOldCategoryId:true}}}>
                         <Price pricing={thisProduct.pricing} />
                    </Link>
                </div>
            </div>
        )
    }
}

export default ChanelCatalogEntryList;
