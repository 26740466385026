import React from 'react';
import { Link } from 'react-router-dom';

export class ShopSale extends React.Component {
	generateLinkForFacet(thisFacetValue) {
		var returnLink = this.props.passedAppFunctions.routeInfo.location.pathname.split('?')[0];
		var search = this.props.passedAppFunctions.routeInfo.location.search || '';
		if (this.props.passedAppFunctions.routeInfo.location.pathname.indexOf('?') > -1) {
			search =
				'?' +
				this.props.passedAppFunctions.routeInfo.location.pathname
					.split('?')
					.splice(1)
					.join('?');
		}
		if (this.props.resultListIsPresent) {
			return this.props.passedAppFunctions.updateUrlWithParams(returnLink + search, { pageNumber: false, facet: thisFacetValue });
		} else {
			return this.props.passedAppFunctions.updateUrlWithParams(returnLink + search, {
				pageNumber: false,
				facet: thisFacetValue,
				forceFlatResults: 'forceFlatResults',
			});
		}
	}

	thisFacetClicked = (event) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.target.parentNode.lastChild.click();
        }
	};

	render() {
		var shouldShowShopSale = false;
		if (this.props.facetsJSON && this.props.facetsJSON['facetsList']) {
			if (this.props.facetsJSON['dil_pricereduced']) {
				for (var i = 0; i < this.props.facetsJSON['dil_pricereduced'].values.length; i++) {
					var facetValue = this.props.facetsJSON['dil_pricereduced'].values[i];
					if (facetValue.dsp == '1' && facetValue.cnt > 0) {
						shouldShowShopSale = true;
					}
				}
			}
			shouldShowShopSale = shouldShowShopSale || (this.props.widgetData.isSaleItem ? (this.props.widgetData.isSaleItem === "true" ? true : false) : false);
		} else {
			//if we don't have facets we are on a landing page where the sales team has included the widget so show it
			shouldShowShopSale = true;
		}
		return (
			<If test={shouldShowShopSale}>
				<div className='filter-list'>
					<h3 className='facet-title'>Sale</h3>
					<fieldset>
						<legend className='sr-only'>Sale</legend>
						<ul className='facet facet-header no-style'>
							<li id='shopSaleChkBox' className='ShopSale'>
								<div className='custom-control custom-checkbox'>
									<input
										className='custom-control-input'
										type='checkbox'
										role='checkbox'
										readOnly
										aria-checked={(this.props.passedAppFunctions.getURLParamValue('facet') || '').indexOf('dil_pricereduced') > -1}
										checked={(this.props.passedAppFunctions.getURLParamValue('facet') || '').indexOf('dil_pricereduced') > -1}
										onKeyUp={this.thisFacetClicked}
									></input>
									<label className='custom-control-label-nocolor custom-control-label' htmlFor='shopSaleChkBox' onClick={(e) => e.target.nextElementSibling.click()}>
										<span className='sr-only'>Sale</span>
									</label>
									<Link to={this.generateLinkForFacet('dil_pricereduced%3A%221%22')} manual_cm_sp={this.props.widgetData.manualCmSp} tabIndex='-1'>
										Sale
									</Link>
								</div>
							</li>
						</ul>
					</fieldset>
				</div>
			</If>
		);
	}
}

export default ShopSale;
