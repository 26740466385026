import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowDownIcon, ArrowRightIcon } from '../../../../../../UI/Icons/Icons';
import { generateLinkForFacetCommon } from '../../../../../../../util/generateLinkForFacet';

export class FacetNavigation extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.state.excludeFacets = props.excludeFacets !== undefined ? props.excludeFacets : false;
		this.state.storedFacet = this.props.passedAppFunctions.getURLParamValue('facet');
		this.state.storedOrderBy = this.props.passedAppFunctions.getURLParamValue('orderBy');
		this.generateLinkForFacet = this.generateLinkForFacet.bind(this);
		this.isFacetSelected = this.isFacetSelected.bind(this);
		this.facetUsageTracking = this.facetUsageTracking.bind(this);
	}
	componentDidMount() {}

	shouldComponentUpdate(nextProps, nextState) {
		var returnValue = false;
		if (nextProps.passedAppFunctions.quickviewIsOpen) {
			return false;
		}
		if (!_.isEqual(nextProps.passedAppFunctions.navigationToggles, this.props.passedAppFunctions.navigationToggles)) {
			returnValue = true;
		}
		if (
			!_.isEqual(nextProps.passedAppFunctions.seoColorInfo, this.props.passedAppFunctions.seoColorInfo) ||
			this.props.passedAppFunctions.getURLParamValue('facet') != this.state.storedFacet
		) {
			this.state.storedFacet = this.props.passedAppFunctions.getURLParamValue('facet');
			returnValue = true;
		}
		if (this.props.passedAppFunctions.getURLParamValue('orderBy') != this.state.storedOrderBy) {
			this.state.storedOrderBy = this.props.passedAppFunctions.getURLParamValue('orderBy');
			returnValue = true;
		}
		if (this.props.excludeFacets === true || !_.isEqual(nextProps.excludeFacets, nextState.excludeFacets)) {
			returnValue = true;
		}
		return returnValue || !_.isEqual(this.props.facetsJSON, nextProps.facetsJSON);
	}
	componentDidUpdate(prevProps) {
		if (prevProps.passedAppFunctions.windowPosition != this.props.passedAppFunctions.windowPosition) {
			this.props.passedAppFunctions.setNavigationToggle('keepPos', true);
			window.scrollTo(0, this.props.passedAppFunctions.windowPosition);
		}
	}
	componentWillUnmount() {
		if (this.props.widgetData.orientation == 'vertical') {
			this.props.passedAppFunctions.setNavigationToggle('keepPos', false);
			this.props.passedAppFunctions.setWindowPosition('windowPosition', 0);
			document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
			document.body.classList.remove('filter-open');
		}
	}
	generateLinkForFacet(thisFacetValue) {
		if (generateLinkForFacetCommon(thisFacetValue, this.props) !== undefined) {
			if (generateLinkForFacetCommon(thisFacetValue, this.props).includes('?exclude') && (generateLinkForFacetCommon(thisFacetValue, this.props).indexOf('?') != generateLinkForFacetCommon(thisFacetValue, this.props).lastIndexOf('?'))) {
				return generateLinkForFacetCommon(thisFacetValue, this.props).split('?exclude').join('&exclude');
			} else {
				return generateLinkForFacetCommon(thisFacetValue, this.props);
			}
		} else {
			return generateLinkForFacetCommon(thisFacetValue, this.props);
		}
	}
	isFacetSelected(passedFacet) {
		var returnBool = false;
		var currentSelectedFacets = this.props.passedAppFunctions.getURLParamValue('facet');
		if (currentSelectedFacets) {
			var currentSelectedFacetsSplit = currentSelectedFacets.split('|');
			for (var i = 0; i < currentSelectedFacetsSplit.length; i++) {
				var thisFacet = currentSelectedFacetsSplit[i];
				if (
					thisFacet ==
					passedFacet
						.split('%2B')
						.join('%25252B')
						.split('%26')
						.join('%2526').split('%25252B').join('%252B').split('%C2%AE').join('%25C2%25AE')
				) {
					return true;
				}
			}
		}
		if (passedFacet == this.props.passedAppFunctions.seoColorInfo.seoColorValue) {
			return true;
		}
		return returnBool;
	}
	facetUsageTracking(thisFacetValue, thisFacetName) {
		this.props.passedAppFunctions.setWindowPosition('windowPosition', window.pageYOffset);
		try {
			var thisFacetSelected = this.isFacetSelected(thisFacetValue.val);
			var facetBreadcrumbForTracking = this.props.widgetData.facetBreadcrumbForTracking;
			if (!thisFacetSelected && facetBreadcrumbForTracking) {
				try {
					//adobe tracking
					trackFacetUsage(thisFacetName, thisFacetValue.dsp);
				} catch (err) {}
			}
		} catch (e) {}
	}
	render() {
		//console.log(this.props.excludeFacets);

		var verticalFacets = [];
		var horizontalFacets = [];
		var containsValues = false;
		var duplicateFacets = false;
		var safeFacets = [];
		var unsafeFacets = [];

		if (this.props.facetsJSON.facetsList) {
			var facetTitles = [];
			for (var i = 0; i < this.props.facetsJSON.facetsList.length; i++) {
				var thisFacet = this.props.facetsJSON.facetsList[i];

				if (this.props.facetsJSON[thisFacet].values.length > 0) {
					containsValues = true;
					if(facetTitles.indexOf(this.props.facetsJSON[thisFacet].facetTitle) > -1 && this.props.passedAppFunctions.contentSwitchPath.indexOf('/search-term/') >= 0){
						duplicateFacets = true;
					}
					else{
						facetTitles.push(this.props.facetsJSON[thisFacet].facetTitle);
					}
				} else {
					containsValues = false;
				}

				if (thisFacet != 'mfName_ntk_cs' && containsValues) {
					if(this.props.facetsJSON[thisFacet].facetTitle == 'size' || this.props.facetsJSON[thisFacet].facetTitle == 'color'){
						safeFacets.push(thisFacet);
					}
					else {
						unsafeFacets.push(thisFacet);
					}
				}
			}
			if(duplicateFacets){
				verticalFacets = safeFacets;
			}
			else {
				verticalFacets = safeFacets.concat(unsafeFacets);
			}
		}

		return (
			<div data-widgetname='FacetNavigation'>
				{this.props.widgetData.orientation == 'vertical' || this.props.facetsInCatNav ? (
					<Choose>
						<When test={this.props.facetsInCatNav && this.props.passedAppFunctions.siteFormat == 'desktop'}>
							<VerticalFacetNavigation
								facetsInCatNav={this.props.facetsInCatNav}
								slotNumber={this.props.slotNumber}
								facetUsageTracking={this.facetUsageTracking}
								isFacetSelected={this.isFacetSelected}
								passedAppFunctions={this.props.passedAppFunctions}
								facetsList={verticalFacets}
								facetsJSON={this.props.facetsJSON}
								widgetData={this.props.widgetData}
								generateLinkForFacet={this.generateLinkForFacet}
								excludeFacets={this.props.excludeFacets}
							/>
						</When>
						<Otherwise>
							<VerticalFacetNavigation
								slotNumber={this.props.slotNumber}
								facetUsageTracking={this.facetUsageTracking}
								isFacetSelected={this.isFacetSelected}
								passedAppFunctions={this.props.passedAppFunctions}
								facetsList={verticalFacets}
								facetsJSON={this.props.facetsJSON}
								widgetData={this.props.widgetData}
								generateLinkForFacet={this.generateLinkForFacet}
								excludeFacets={this.props.excludeFacets}
							/>
						</Otherwise>
					</Choose>
				) : (
					<HorizontalFacetNavigation
						facetUsageTracking={this.facetUsageTracking}
						isFacetSelected={this.isFacetSelected}
						passedAppFunctions={this.props.passedAppFunctions}
						facetsList={horizontalFacets}
						facetsJSON={this.props.facetsJSON}
						widgetData={this.props.widgetData}
						generateLinkForFacet={this.generateLinkForFacet}
						toggleFacetMenu={this.props.toggleFacetMenu}
						toggleSortMenu={this.props.toggleSortMenu}
						excludeFacets={this.state.excludeFacets !== undefined ? this.state.excludeFacets : ''}
					/>
				)}
			</div>
		);
	}
}

class HorizontalFacetNavigation extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.state.excludeFacets = props.excludeFacets;
	}
	componentDidMount() {
		//console.log(this.props.excludeFacets);
	}
	selectedFacets() {
		var existingFacets = this.props.passedAppFunctions.getURLParamValue('facet');
		var existingFacetsArray = [];
		var returnFacetArray = [];
		if (existingFacets) {
			existingFacetsArray = existingFacets.split('|');
		}
		for (var i = 0; i < existingFacetsArray.length; i++) {
			if (existingFacetsArray[i].indexOf('dil_shipinternational') == -1) {
				returnFacetArray.push(existingFacetsArray[i]);
			}
		}
		if (this.props.passedAppFunctions.seoColorInfo.seoColorValue) {
			returnFacetArray.push('seoColor');
		}
		return returnFacetArray;
	}
	getFacetNameFromFacetValue(facetValue) {
		var returnString = 'facet';
		try {
			if (facetValue == 'seoColor') {
				return this.props.passedAppFunctions.seoColorInfo.seoColorDisplay;
			}
			var facetName = facetValue.split('%3A')[0];
			if (this.props.facetsJSON[facetName]) {
				if (facetName == 'dil_pricereduced') return 'Sale';
				for (var i = 0; i < this.props.facetsJSON[facetName].values.length; i++) {
					var thisFacetValue = this.props.facetsJSON[facetName].values[i];
					if (
						facetValue ==
						thisFacetValue.val
							.split('%2B')
							.join('%25252B')
							.split('%26')
							.join('%2526').split('%25252B').join('%252B').split('%C2%AE').join('%25C2%25AE')
					) {
						if (facetName == 'dil_newArrival') return 'New Arrivals';
						return thisFacetValue.dsp;
					}
				}
			} else {
				if (facetName == 'dil_newArrival') return 'New Arrivals';
				if (facetName == 'dil_pricereduced') return 'Sale';
				return facetValue
					.split('%3A')[1]
					.split('%22')
					.join('')
					.split('%27')
					.join("'");
			}
		} catch (e) {
			// console.log(e)
		}
		return returnString;
	}
	resetKeepPos = () => {
		this.props.passedAppFunctions.setNavigationToggle('keepPos', false);
	};
	componentWillUpdate() {}
	render() {
		return (
			<If test={this.props.passedAppFunctions.siteFormat == 'desktop'}>
				<div id='selectFilterFacets'>
					<If test={this.selectedFacets().length > 0}>
						<span>
							<Link rel='nofollow' className='clearAllFilters' to={this.props.generateLinkForFacet(false)} onClick={this.resetKeepPos}>
								clear all
							</Link>
						</span>
						<ForEach var='thisFacetValue' items={this.selectedFacets()}>
							<If test={this.getFacetNameFromFacetValue(thisFacetValue) != 'facet'}>
								<span className='selectedFilter' key={thisFacetValue}>
									<Link rel='nofollow' to={this.props.generateLinkForFacet(thisFacetValue)} onClick={this.resetKeepPos}>
										{this.getFacetNameFromFacetValue(thisFacetValue)
											.split('#double;')
											.join('"')}
										<svg
											role='img'
											aria-labelledby={
												'clearFilter' +
												this.getFacetNameFromFacetValue(thisFacetValue)
													.split('#double;')
													.join('"')
													.split(' ')
													.join('')
											}
											height='10'
											width='10'
											xmlns='http://www.w3.org/2000/svg'
											viewBox='0 0 48.77 48.77'>
											<title
												id={
													'clearFilter' +
													this.getFacetNameFromFacetValue(thisFacetValue)
														.split('#double;')
														.join('"')
														.split(' ')
														.join('')
												}>
												clear filter
											</title>
											<g data-name='Layer 2'>
												<g data-name='Layer 1'>
													<path d='M25.8,24.39,48.48,1.71A1,1,0,0,0,47.07.29L24.39,23,1.71.29A1,1,0,0,0,.29,1.71L23,24.39.29,47.07a1,1,0,1,0,1.41,1.41L24.39,25.8,47.07,48.48a1,1,0,0,0,1.41-1.41Z' />
												</g>
											</g>
										</svg>
									</Link>
								</span>
							</If>
						</ForEach>
					</If>
				</div>
			</If>
		);
	}
}

class VerticalFacetNavigation extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	componentDidMount() {}

	render() {
		if (this.props.excludeFacets === undefined && document.querySelector('.breadCrumb').children !== null) {
			var excludeFacets = false;
			var containsBreadcrumbTrail = document.querySelector('.breadCrumb').children;
			if (
				containsBreadcrumbTrail !== null &&
				containsBreadcrumbTrail.length === 1 &&
				this.props.passedAppFunctions.contentSwitchPath.indexOf('/search-term/') >= 0
			) {
				excludeFacets = true;
			}
		}

		var filterOutFacets = {};
		if ((this.props.excludeFacets !== undefined && this.props.excludeFacets === true) || (excludeFacets !== undefined && excludeFacets === true)) {
			//facet new arrivals
			this.props.facetsJSON.dil_newArrival !== undefined ? (filterOutFacets.dil_newArrival = this.props.facetsJSON.dil_newArrival) : '';
			//facet size
			this.props.facetsJSON.ads_f11008_ntk_cs !== undefined ? (filterOutFacets.ads_f11008_ntk_cs = this.props.facetsJSON.ads_f11008_ntk_cs) : '';
			//facet color
			this.props.facetsJSON.ads_f11001_ntk_cs !== undefined ? (filterOutFacets.ads_f11001_ntk_cs = this.props.facetsJSON.ads_f11001_ntk_cs) : '';
			//facet list
			if (this.props.facetsJSON.facetsList !== undefined) {
				var filtered = [];
				for (var i = 0; i < this.props.facetsJSON.facetsList.length; i++) {
					this.props.facetsJSON.facetsList[i] === 'ads_f11001_ntk_cs' ? filtered.push('ads_f11001_ntk_cs') : '';
					this.props.facetsJSON.facetsList[i] === 'ads_f11008_ntk_cs' ? filtered.push('ads_f11008_ntk_cs') : '';
				}
				var facetsList = filtered.filter(function(el) {
					return el != '';
				});
				filterOutFacets.facetsList = facetsList;
			}
		}
		var facetsList = Object.keys(filterOutFacets).length !== 0 ? filterOutFacets.facetsList : this.props.facetsList;
		return (
			<div className='vert-facets'>
				<If test={this.props.passedAppFunctions.siteFormat == 'desktop'}>
					{/* FOR DESKTOP */}
					<div className={`filter-list`}>
						{facetsList.map((thisFacetName, index) => {
							return (
								<If test={(thisFacetName === 'ads_f11001_ntk_cs' && this.props.facetsInCatNav === undefined) || (thisFacetName !== 'ads_f11001_ntk_cs' && this.props.facetsInCatNav === undefined)}>									
									<FacetItemDesktop
										facetUsageTracking={this.props.facetUsageTracking}
										key={thisFacetName + index}
										thisFacetName={thisFacetName}
										isFacetSelected={this.props.isFacetSelected}
										passedAppFunctions={this.props.passedAppFunctions}
										facetsJSON={this.props.excludeFacets === true && Object.keys(filterOutFacets).length !== 0 ? filterOutFacets : this.props.facetsJSON}
										//facetsJSON={this.props.facetsJSON}
										widgetData={this.props.widgetData}
										generateLinkForFacet={this.props.generateLinkForFacet}
										closeOnMount={true}
										excludeFacets={this.props.excludeFacets !== undefined ? this.props.excludeFacets : ''}
									/>
								</If>
							);
						})}
					</div>
				</If>
				<If test={this.props.passedAppFunctions.siteFormat == 'tablet'}>
					{/* FOR TABLET */}
					<div className={`filter-list`}>
						{facetsList.map((thisFacetName, index) => {
							return (
								<FacetItemTablet
									facetUsageTracking={this.props.facetUsageTracking}
									key={index}
									thisFacetName={thisFacetName}
									isFacetSelected={this.props.isFacetSelected}
									passedAppFunctions={this.props.passedAppFunctions}
									facetsJSON={this.props.excludeFacets === true && Object.keys(filterOutFacets).length !== 0 ? filterOutFacets : this.props.facetsJSON}
									widgetData={this.props.widgetData}
									generateLinkForFacet={this.props.generateLinkForFacet}
									excludeFacets={this.props.excludeFacets !== undefined ? this.props.excludeFacets : ''}
								/>
							);
						})}
					</div>
				</If>
			</div>
		);
	}
}

class FacetItemDesktop extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.facetToggle = this.facetToggle.bind(this);
	}
	componentDidMount() {}
	facetToggle() {
		this.props.passedAppFunctions.setNavigationToggle(this.props.thisFacetName);
	}

	thisFacetClicked = (event) => {
		if (event.key === 'Enter' || event.key === ' ') {
			event.target.parentNode.lastChild.click();
		}
	};

	render() {
		var thisFacetName = this.props.thisFacetName;
		return (
			<React.Fragment>
				{thisFacetName != 'mfName_ntk_cs' && this.props.facetsJSON[thisFacetName].values.length > 1 ? (
					<div className='vert-facets' key={thisFacetName}>
						<fieldset>
							<legend className='sr-only'>{this.props.facetsJSON[thisFacetName].facetTitle}</legend>
							<button
								className={`${this.props.passedAppFunctions.navigationToggles[thisFacetName] ? 'open' : ''} facet-title facet-title-desktop`}
								onClick={this.facetToggle}
								aria-controls={thisFacetName}
								aria-expanded={this.props.passedAppFunctions.navigationToggles[thisFacetName] ? 'true' : 'false'}
							>
								{this.props.facetsJSON[thisFacetName].facetTitle}
							</button>

							<ul className='facet desktop-vert-facet' aria-labelledby='filter_type' id={thisFacetName}>
								{this.props.facetsJSON[thisFacetName].values.map((thisFacetValue, index) =>
									thisFacetValue.dsp.length <= 0 || thisFacetValue.dsp.split('#double;').join('"').indexOf('Multi/Misc') >= 0 ? (
										<></>
									) : (
										<li
											key={thisFacetValue.dsp}
											className={`custom-control custom-checkbox ${this.props.facetsJSON[thisFacetName].facetTitle == 'color' ? 'color-label' : ''}`}
										>
											{this.props.facetsJSON[thisFacetName].facetTitle == 'color' ? (
												<span
													className='navColorFacet'
													id={thisFacetValue.dsp.split('/').join('')}
													aria-labelledby={thisFacetValue.dsp}
													alt={thisFacetValue.dsp.split('/').join('')}
													onClick={(e) => e.target.parentNode.lastChild.click()}
												></span>
											) : (
												''
											)}
											<input
												className='custom-control-input'
												readOnly
												checked={this.props.isFacetSelected(thisFacetValue.val)}
												aria-checked={this.props.isFacetSelected(thisFacetValue.val)}
												type='checkbox'
												name={thisFacetValue.val}
												id={thisFacetValue.val}
												data-twist={this.props.facetsJSON[thisFacetName].facetTitle}
												data-facetname={this.props.facetsJSON[thisFacetName].facetTitle}
												tabIndex='0'
												onKeyUp={this.thisFacetClicked}
											/>
											<label className='custom-control-label' htmlFor={thisFacetValue.val} onClick={(e) => e.target.nextElementSibling.click()}>
												<span className='sr-only'>{thisFacetValue.dsp.split('#double;').join('"')}</span>
											</label>
											<Link
												onClick={() => this.props.facetUsageTracking(thisFacetValue, this.props.facetsJSON[thisFacetName].facetTitle)}
												rel={
													this.props.isFacetSelected(thisFacetValue.val) ||
													this.props.generateLinkForFacet(thisFacetValue.val.split('%2B').join('%25252B').split('%26').join('%2526').split('%25252B').join('%252B').split('%C2%AE').join('%25C2%25AE')).indexOf('facet=') > -1
														? 'nofollow'
														: undefined
												}
												to={this.props.generateLinkForFacet(thisFacetValue.val)}
												tabIndex='-1'
											>
												{thisFacetValue.dsp.split('#double;').join('"')}
											</Link>
										</li>
									)
								)}
							</ul>
						</fieldset>
					</div>
				) : (
					''
				)}
			</React.Fragment>
		);
	}
}

class FacetItemTablet extends React.Component {
	constructor(props) {
		super(props);
		this.facetToggle = this.facetToggle.bind(this);
	}
	componentDidMount() {
		//console.log(this.props.excludeFacets);
	}
	facetToggle() {
		this.props.passedAppFunctions.setNavigationToggle(this.props.thisFacetName);
	}
	render() {
		var thisFacetName = this.props.thisFacetName;
		return (
			<React.Fragment>
				{thisFacetName != 'mfName_ntk_cs' && this.props.facetsJSON[thisFacetName].values.length > 1 ? (
					<div className='vert-facets' key={thisFacetName} role='button' aria-haspopup='menu'>
						<button
							className={`${this.props.passedAppFunctions.navigationToggles[thisFacetName] ? 'open' : ''} facet-title`}
							onClick={this.facetToggle}
							aria-controls={thisFacetName}
							aria-expanded={this.props.passedAppFunctions.navigationToggles[thisFacetName] ? 'true' : 'false'}
						>
							{this.props.facetsJSON[thisFacetName].facetTitle}
						</button>
						<fieldset>
							<legend className='sr-only'>{this.props.facetsJSON[thisFacetName].facetTitle}</legend>
							<ul className='facet' role='menu' id={thisFacetName}>
								{this.props.facetsJSON[thisFacetName].values.map((thisFacetValue, index) =>
									thisFacetValue.dsp.length <= 0 || thisFacetValue.dsp.split('#double;').join('"').indexOf('Multi/Misc') >= 0 ? (
										<></>
									) : (
										<li key={thisFacetValue.dsp} className={`custom-control custom-checkbox ${this.props.facetsJSON[thisFacetName].facetTitle == 'color' ? 'color-label' : ''}`}>
											{this.props.facetsJSON[thisFacetName].facetTitle == 'color' ? (
												<span
													className='navColorFacet'
													id={thisFacetValue.dsp.split('/').join('')}
													aria-labelledby={thisFacetValue.dsp}
													alt={thisFacetValue.dsp.split('/').join('')}
													onClick={(e) => e.target.parentNode.lastChild.click()}
												></span>
											) : (
												''
											)}
											<input
												className='custom-control-input'
												readOnly
												checked={this.props.isFacetSelected(thisFacetValue.val)}
												type='checkbox'
												name={thisFacetValue.val}
												id={thisFacetValue.val}
												data-twist={this.props.facetsJSON[thisFacetName].facetTitle}
												data-facetname={this.props.facetsJSON[thisFacetName].facetTitle}
												tabIndex='0'
											/>
											<label
												className={
													this.props.facetsJSON[thisFacetName].facetTitle == 'color'
														? 'custom-control-label'
														: 'custom-control-label-nocolor custom-control-label'
												}
												htmlFor={thisFacetValue.val}
												onClick={(e) => e.target.nextElementSibling.click()}
											>
												<span className='sr-only'>{thisFacetValue.dsp.split('#double;').join('"')}</span>
											</label>
											<Link
												onClick={() => this.props.facetUsageTracking(thisFacetValue, this.props.facetsJSON[thisFacetName].facetTitle)}
												rel={
													this.props.isFacetSelected(thisFacetValue.val) ||
													this.props.generateLinkForFacet(thisFacetValue.val.split('%2B').join('%25252B').split('%26').join('%2526').split('%25252B').join('%252B').split('%C2%AE').join('%25C2%25AE')).indexOf('facet=') > -1
														? 'nofollow'
														: undefined
												}
												to={this.props.generateLinkForFacet(thisFacetValue.val.split('%C2%AE').join('%25C2%25AE'))}
												tabIndex='-1'
											>
												{thisFacetValue.dsp.split('#double;').join('"')}

											</Link>
										</li>
									)
								)}
							</ul>
						</fieldset>
					</div>
				) : (
					''
				)}
			</React.Fragment>
		);
	}
}

export default FacetNavigation;
