import React from 'react';
import axios from 'axios';
import { nextWednesday } from 'date-fns';

export class Contest extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			contestId: this.props.widgetData.contestId,
			restrictions: this.props.widgetData.restrictions,
			email: '',
			firstName: '',
			lastName: '',
			address1: '',
			address2: '',
			city: '',
			stateCode: '',
			zip: '',
			intl: '',
			phone: '',
			dob: '',
			receiveEmails: true,
			response1: this.props.passedAppFunctions.getURLParamValue('r1') || '',
			response2: this.props.passedAppFunctions.getURLParamValue('r2') || '',
			checked: false,
			checkedMinAge: true,
			error: false,
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleCheck = this.handleCheck.bind(this);
		this.handleCheckMinAge = this.handleCheckMinAge.bind(this);
		this.handleEmailCheck = this.handleEmailCheck.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.cleanFields = this.cleanFields.bind(this);
		this.isValidPhone = this.isValidPhone.bind(this);
		this.formatPhoneNumber = this.formatPhoneNumber.bind(this);
		this.removeNonNumerics = this.removeNonNumerics.bind(this);
	}
	
	cleanFields() {
		var inputZip = document.getElementById("contestZipCode");
		$(inputZip).val($(inputZip).val().replaceAll(/[^0-9]/g, ''));
	}

	removeNonNumerics(inputString) {
		return inputString.replaceAll(/[^0-9]/g, '');
	}

	isValidPhone(testString) {
		return !isNaN(testString * 1) && testString.length == 10;
	}

	formatPhoneNumber(inputString) {
		var returnString = '';
		if (inputString.length > 0) {
			returnString = `(${inputString.substring(0, 3)}`;
		}
		if (inputString.length > 3) {
			returnString += `) ${inputString.substring(3, 6)}`;
		}
		if (inputString.length > 6) {
			returnString += `-${inputString.substring(6)}`;
		}
		return returnString;
	}

	handleChange(e) {
		var inputName = e.target.name
		var inputValue = e.target.value
		if (inputName === 'phone') {
			inputValue = this.removeNonNumerics(inputValue);
		}
		this.setState({ [inputName] : inputValue });
		if (document.querySelectorAll('.has-error').length > 0) {
			this.setState({ error: true });
		} else {
			this.setState({ error: false });
		}		
	}

	handleCheck() {
		this.setState({ checked: !this.state.checked });
		if(this.props.widgetData.requireTerms){
			var agreeToTermsInput = document.getElementById("agreeToTerms").parentElement.parentElement;
			if(this.state.checked){
				!agreeToTermsInput.classList.contains("has-error") ? 
				agreeToTermsInput.classList.add("has-error") : '';
			}
			else{
				agreeToTermsInput.classList.contains("has-error") ? 
				agreeToTermsInput.classList.remove("has-error") : '';
			}
		}
	}

	handleCheckMinAge() {
		this.setState({ checkedMinAge: !this.state.checkedMinAge });
		if(this.props.widgetData.confirmAge){
			var minimumAgeInput = document.getElementById("minimumAge").parentElement.parentElement;
			if(this.state.checkedMinAge){
				!minimumAgeInput.classList.contains("has-error") ? 
				minimumAgeInput.classList.add("has-error") : '';
			}
			else{
				minimumAgeInput.classList.contains("has-error") ? 
				minimumAgeInput.classList.remove("has-error") : '';
			}
		}
	}

	handleEmailCheck(e) {
		this.setState({ receiveEmails: e.target.checked });
	}

	handleSubmit(event) {
		event.preventDefault();
		
		const data = {
			contestId: this.state.contestId,
			restrictions: this.state.restrictions,
			email: this.state.email,
			firstName: this.state.firstName,
			lastName: this.state.lastName,
			address1: this.state.address1,
			address2: this.state.address2,
			city: this.state.city,
			stateCode: this.state.stateCode,
			zip: this.state.zip,
			intl: this.state.intl,
			phone: this.state.phone,
			dob: null,
			receiveEmails: this.state.receiveEmails,
			response1: this.state.response1,
			response2: this.state.response2,
		};
		console.log("address1:" + data.address1);
		if(this.state.dob){
			if(new Date(this.state.dob)){
				data.dob = new Date(this.state.dob).getTime();
			}
		}

		var formValid = false;

		if(data.email && data.lastName && data.address1 && (data.address1.length <= 30) && data.intl && data.city && (data.city.length <= 20) && (data.address2.length <= 30) && data.phone && (!this.props.widgetData.requireTerms || this.state.checked)){
			formValid = true;
			if(this.props.widgetData.requestBday && !data.dob 
				|| this.props.widgetData.options[0]?.required && !data.response1
				|| this.props.widgetData.options[1]?.required && !data.response2 
				|| this.props.widgetData.confirmAge && !this.state.checkedMinAge){
				formValid = false;
			}
		}
		// triggering a reformat on the phone field 
		if(!formValid){
			this.setState({phone: this.state.phone})
		}
		if(this.props.widgetData.requireTerms){
			var agreeToTermsInput = document.getElementById("agreeToTerms").parentElement.parentElement;
			if(!this.state.checked){
				!agreeToTermsInput.classList.contains("has-error") ? 
				agreeToTermsInput.classList.add("has-error") : '';
			}
			else{
				agreeToTermsInput.classList.contains("has-error") ? 
				agreeToTermsInput.classList.remove("has-error") : '';
			}
		}

		if(this.props.widgetData.confirmAge){
			var minimumAgeInput = document.getElementById("minimumAge").parentElement.parentElement;
			if(!this.state.checkedMinAge){
				!minimumAgeInput.classList.contains("has-error") ? 
				minimumAgeInput.classList.add("has-error") : '';
			}
			else{
				minimumAgeInput.classList.contains("has-error") ? 
				minimumAgeInput.classList.remove("has-error") : '';
			}
		}

		if(formValid){
			axios
				.post('/wcs/resources/store/301/contest', data)
				.then((res) => {
					//if(res.data.message == 'Already Entered.')
					const msgTable = document.getElementById(this.props.widgetData.successDivIdToShow);
					const contestForm2 = document.querySelector('.contest-form');
					const heroImage = document.querySelector('[data-widgettype*="ContentRecommendation"]');
					msgTable.style.display = 'block';
					contestForm2.style.display = 'none';
					heroImage.style.display = 'none';
					setTimeout(() => {
						window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
					}, 600);
					window.scrollTo(0, 0);
				})
				.catch((error) => {
					const errorBox = document.getElementById('contestError');
					errorBox.innerText=error.response.data.message;
					errorBox.style.display = 'block';
					const errorBox_X = errorBox.getBoundingClientRect().x;
					const errorBox_Y = errorBox.getBoundingClientRect().y;
					window.scrollTo(errorBox_X,errorBox_Y);
				});
		} 

		
	}


	render() {
		return (
			<div className='contest-form'>
			
				{this.props.widgetData.contestActive ? (
					<form id='contestForm' className='mx-auto col-xl-8 col-lg-10 col-md-10'>
					
						<div className='wrapper'>
							<div>
								
								<div id='contest-contestant'>
									<h1>Contestant Information</h1>
									<div id="contestError" className="contest-error" style={{ display: 'none' }}></div>
										<div className='form-group required'>
										<label htmlFor='contest-verify-email' className='control-label'>
											Email
										</label>
										<input
											name='email'
											size='30'
											maxLength='50'
											value={this.state.email}
											type='email'
											id='contest-verify-email'
											className='form-control'
											data-validate='email'
											title='Enter a valid email address.'
											onChange={this.handleChange.bind(this)}
											required
										/>
									</div>
									<div className='form-group required'>
										<label htmlFor='contest-first-name' className='control-label'>
											First Name
										</label>
										<input
											name='firstName'
											value={this.state.firstName}
											size='30'
											maxLength='16'
											type='text'
											id='contest-first-name'
											className='form-control'
											data-validate='name'
											data-toggle='tooltip'
											data-placement='top'
											title='Enter a valid first name.'
											onChange={this.handleChange.bind(this)}
											required
										/>
									</div>
									<div className='form-group required'>
										<label htmlFor='contest-last-name' className='control-label'>
											Last Name
										</label>
										<input
											size='30'
											maxLength='20'
											value={this.state.lastName}
											name='lastName'
											type='text'
											id='contest-last-name'
											className='form-control'
											data-validate='name'
											data-toggle='tooltip'
											data-placement='top'
											title='Enter a valid last name.'
											onChange={this.handleChange.bind(this)}
											required
										/>
									</div>
									{this.props.widgetData.requestBday ? 
									<div className='birthDate form-group required'>
									<label htmlFor='contest-dob' className='control-label'>
										Birth date 
									</label>
									
										<input
											value={this.state.dob}
											name='dob'
											type='date'
											id='contest-dob'
											data-validate='required, dob'
											title='Enter a valid date.'
											max={new Date().toLocaleDateString('en-ca')}
											onChange={this.handleChange.bind(this)}
											className="form-control"
											required
										/>

								</div>
									: ''}

									<div className='form-group required'>
										<label htmlFor='state' className='control-label'>
											Do you live in the United States?
										</label>
										<select
											id='contest-country'
											name='intl'
											className='form-control disregard-dropdown'
											data-validate='required'
											data-toggle='tooltip'
											value={this.state.intl}
											data-placement='top'
											title='Please select a country.'
											required
											onChange={this.handleChange.bind(this)}>
											<option></option>
											<option value='N'> Yes </option>
											<option value='Y'> No </option>
										</select>
									</div>
									<div className='form-group required'>
										<label htmlFor='contest-address-1' className='control-label'>
											Address Line 1
										</label>

										<input
											type='text'
											id='contest-address-1'
											className='form-control'
											name='address1'
											size='30'
											value={this.state.address1}
											
											data-validate='required, maxLength(30)'
											data-toggle='tooltip'
											data-placement='top'
											title='Enter a valid address. (Max length is 30)'
											onChange={this.handleChange.bind(this)}
											required
										/>
									</div>
									<div className='form-group'>
										<label htmlFor='contest-address-2' className='control-label'>
											Address Line 2
										</label>
										<input 
										type='text' 
										id='contest-address-2' 
										data-validate='maxLength(30)'
										className='form-control' 
										name='address2' 
										size='30'  
										title='Enter a valid address. (Max length is 30)'
										onChange={this.handleChange.bind(this)}	
										/>
									</div>
									<div className='form-group required'>
										<label htmlFor='contest-city' className='control-label'>
											City
										</label>
										<input
											type='text'
											id='contest-city'
											className='form-control'
											name='city'
											value={this.state.city}
											size='30'
											
											data-validate='city,required,maxLength(20)'
											data-toggle='tooltip'
											data-placement='top'
											title='Enter a valid city. (Max length is 20)'
											required
											onChange={this.handleChange.bind(this)}
										/>
									</div>
									<div className='form-group required'>
										<label htmlFor='contestState' className='control-label'>
											State
										</label>
										<select
											id='contestState'
											name='stateCode'
											className='form-control disregard-dropdown'
											data-validate='required'
											data-toggle='tooltip'
											value={this.state.stateCode}
											data-placement='top'
											title='Select your state.'
											onChange={this.handleChange.bind(this)}>
											<option></option>
											<option value='AL'> Alabama </option>
											<option value='AK'> Alaska </option>
											<option value='AZ'> Arizona </option>
											<option value='AR'> Arkansas </option>
											<option value='AA'> Armed Forces Americas </option>
											<option value='AE'> Armed Forces Europe </option>
											<option value='AP'> Armed Forces Pacific </option>
											<option value='CA'> California </option>
											<option value='CO'> Colorado </option>
											<option value='CT'> Connecticut </option>
											<option value='DE'> Delaware </option>
											<option value='DC'> District of Columbia </option>
											<option value='FL'> Florida </option>
											<option value='GA'> Georgia </option>
											<option value='GU'> Guam </option>
											<option value='HI'> Hawaii </option>
											<option value='ID'> Idaho </option>
											<option value='IL'> Illinois </option>
											<option value='IN'> Indiana </option>
											<option value='IA'> Iowa </option>
											<option value='KS'> Kansas </option>
											<option value='KY'> Kentucky </option>
											<option value='LA'> Louisiana </option>
											<option value='ME'> Maine </option>
											<option value='MD'> Maryland </option>
											<option value='MA'> Massachusetts </option>
											<option value='MI'> Michigan </option>
											<option value='MN'> Minnesota </option>
											<option value='MS'> Mississippi </option>
											<option value='MO'> Missouri </option>
											<option value='MT'> Montana </option>
											<option value='NE'> Nebraska </option>
											<option value='NV'> Nevada </option>
											<option value='NH'> New Hampshire </option>
											<option value='NJ'> New Jersey </option>
											<option value='NM'> New Mexico </option>
											<option value='NY'> New York </option>
											<option value='NC'> North Carolina </option>
											<option value='ND'> North Dakota </option>
											<option value='OH'> Ohio </option>
											<option value='OK'> Oklahoma </option>
											<option value='OR'> Oregon </option>
											<option value='PA'> Pennsylvania </option>
											<option value='PR'> Puerto Rico </option>
											<option value='RI'> Rhode Island </option>
											<option value='SC'> South Carolina </option>
											<option value='SD'> South Dakota </option>
											<option value='TN'> Tennessee </option>
											<option value='TX'> Texas </option>
											<option value='VI'> U.S. Virgin Islands </option>
											<option value='UT'> Utah </option>
											<option value='VT'> Vermont </option>
											<option value='VA'> Virginia </option>
											<option value='WA'> Washington </option>
											<option value='WV'> West Virginia </option>
											<option value='WI'> Wisconsin </option>
											<option value='WY'> Wyoming </option>
										</select>
									</div>
									<div className='form-group required'>
										<label htmlFor='contestZipCode' className='control-label'>
											Zip Code
										</label>
										<input
											type='text'
											id='contestZipCode'
											className='form-control'
											data-validate='required,zip'
											name='zip'
											value={this.state.zip}
											size='30'
											maxLength='5'
											pattern='[0-9]*'
											data-toggle='tooltip'
											data-placement='top'
											title='Enter a valid zip code.'
											onInput={this.cleanFields.bind(this)}
											onChange={this.handleChange.bind(this)}
										/>
									</div>
									<div className='form-group required'>
										<label htmlFor='contest-phone' className='control-label'>
											Phone Number
										</label>
										<input
											type='tel'
											id='contest-phone'
											className='form-control'
											maxLength='14'
											name='phone'
											value={this.formatPhoneNumber(this.state.phone)}
											data-validate='phone'
											data-toggle='tooltip'
											data-placement='top'
											title='Enter a valid phone number.'
											required
											onChange={this.handleChange.bind(this)}
											autoComplete='off'
										/>
									</div>
									{this.props.widgetData.options.map((option, index) => (
										option.type == 'dropdown' ? 
										<div className={option.required ? 'form-group required' : 'form-group'}>
										<label htmlFor={'response' + index + 1} className='control-label'>
											{option.label}
										</label>
										<select
											id={'response' + (index + 1)}
											name={'response' + (index + 1)}
											data-validate={option.required ? 'required' : ''}
											className='form-control disregard-dropdown'
											data-toggle='tooltip'
											value={index == 0 ? this.state.option1 : this.state.option2}
											data-placement='top'
											title="Select an option."
											required={option.required}
											onChange={this.handleChange.bind(this)}>
											<option></option>
											{option.values.length > 1 ? option.values.map((value, indx) => (
											<option value={value}>
												{value}
											</option>)) : ''}
										</select>
									</div> : 
											<div className={option.required ? 'form-group required' : 'form-group'}>
											<label htmlFor={'response' + (index + 1)} className='control-label'>
												{option.label}
											</label>
											<input
												id={'response' + (index + 1)}
												type='text'
												data-validate={option.required ? 'required' : ''}
												required={option.required}
												className='form-control'
												name={'response' + (index + 1)}
												value={index == 0 ? this.state.option1 : this.state.option2}
												data-toggle='tooltip'
												data-placement='top'
												title={option.label}
												onChange={this.handleChange.bind(this)}
											/>
										</div>
									))

									}
									<div className='form-group' style={{ display: 'none' }}>
										<input type='text' value={this.state.response1} size='30' maxLength='28' data-placement='top' onChange={this.handleChange.bind(this)} />
										<input type='text' value={this.state.response2} size='30' maxLength='28' data-placement='top' onChange={this.handleChange.bind(this)} />
									</div>
								</div>
							</div>

							<div id='contest-footer'>
								<div className='d-flex flex-column'>
									<div className='flex flex-row'>
										<div className='dillards-emails'>
											<label id='opt-in-label' htmlFor='special-offer-optin' className='control-label'>
												<input
													name='receiveEmails'
													id='special-offer-optin'
													type='checkbox'
													className='form-control'
													data-novalidation=''
													value={this.state.receiveEmails}
													onChange={this.handleEmailCheck.bind(this)}
													checked={this.state.receiveEmails}
												/>
												<span>Sign me up for Dillard's emails</span>
											</label>
										</div>
										{this.props.widgetData.requireTerms ? 
										<div className='required'>
											<label htmlFor='agreeToTerms' className='control-label'>
												<input
													name='consent'
													className='form-control'
													type='checkbox'
													id='agreeToTerms'
													defaultChecked={this.state.checked}
													onChange={this.handleCheck.bind(this)}
												/>
												<span><span className='requiredAsterisk'>* </span>I have read the Terms &amp; Conditions</span>
											</label>
											<p className="help-block txt-error" id="agreeToTerms-error-message">This field is required.</p>
										</div>
										: ''}
										{this.props.widgetData.confirmAge ? 
											<div className='required'>
											<label htmlFor='minimumAge' className='control-label'>
											<input
												name='minimumAge'
												className='form-control'
												type='checkbox'
												id='minimumAge'
												defaultChecked={this.state.checkedMinAge}
												onChange={this.handleCheckMinAge.bind(this)}
											/>
											<span><span className='requiredAsterisk'>* </span>I am {this.props.widgetData.minimumAge} or older </span>
											</label>
											<p className="help-block txt-error" id="minimumAge-error-message">This field is required.</p>
										</div>
										: ''}
									</div>
									<div className='form-group d-flex flex-column'>
										<button
											href='javascript:void(0)'
											id='contest-submit'
											type='button'
											onClick={this.handleSubmit.bind(this)}
											className='btn btn-primary pull-right w-25 mt-3'>
											Submit
										</button>
										<p className='mb-2 mt-3 font-weight-bold'>Must be {this.props.widgetData.minimumAge} or older to enter*</p>
									</div>
								</div>
							</div>
						</div>
					</form>
				) : (
					''
				)}
				{this.props.widgetData.beforeStartDate ? (
					<div id='beforeStartDate'>
						<h1>Sorry, this contest is currently not accepting entries.</h1>
					</div>
				) : (
					''
				)}

				{this.props.widgetData.afterEndDate ? (
					<div id='afterEndDate'>
						<h1>Sorry, this contest has ended.</h1>
					</div>
				) : (
					''
				)}
			</div>
		);
	}
}

export default Contest;
