import React from 'react';
import { Link } from 'react-router-dom';

export class SiteMapDisplay extends React.Component {
    render() {
        var widgetData = this.props.widgetData;
        return (
            <div className='SiteMapDisplay col-sm-12' id='SiteMapDisplay'>
            <h1 alt="Dillard's Site Map">Site Map</h1>
                {
                    widgetData.topCategories.map((thisTopCategory, topCategoryIndex)=>
                        <div className='col-12 sitemapCat' key={thisTopCategory.topCategoryName}>
                            <Link className='sitemapCat__deptName' to={thisTopCategory.topCategoryURL} >{thisTopCategory.topCategoryName}</Link>
                            <ul className='sitemapCat__subCat'>
                                {
                                    thisTopCategory.subCategories.map((thisSubCategory, subCategoryIndex)=>
                                        <li key={thisSubCategory.subCategoryURL}>
                                            <Link className='sitemapCat__subCatName' to={thisSubCategory.subCategoryURL} >{thisSubCategory.subCategoryName}</Link>
                                            <ul className='sitemapCat__subSub'>
                                                {
                                                    thisSubCategory.subSubCategories.map((thisSubSubCategory, subSubCategoryIndex)=>
                                                        <li key={thisSubSubCategory.subSubCategoryURL}>
                                                            <Link className='sitemapCat__subSubName' to={thisSubSubCategory.subSubCategoryURL} >{thisSubSubCategory.subSubCategoryName}</Link>
                                                        </li>
                                                    )
                                                }
                                            </ul>
                                        </li>
                                    )
                                }
                            </ul>
                        </div>
                    )
                }
            </div>
        );
    }
}

export default SiteMapDisplay;
