import React, { Component } from 'react';
import { SetInnerHTML } from '../SetInnerHTML/SetInnerHTML';

export class Price extends Component {
	constructor(props){
		super(props);
		this.forceRender = this.forceRender.bind(this);
	}
	forceRender(){
		this.setState({});
	}
	componentDidMount(){
		$(window).on('priceRender', this.forceRender);
	}
	componentWillUnmount(){
		$(window).off('priceRender', this.forceRender);
	}
	render() {
		var thisPricing = this.props.pricing;
		try{
			if(typeof window != 'undefined' && thisPricing){
				if(window.injectedPriceFunction){
					thisPricing = window.injectedPriceFunction(thisPricing);
				}
				if(window.injectedPriceFunctionArray && window.injectedPriceFunctionArray.length > 0){
					for(var iPriceInject = 0; iPriceInject < window.injectedPriceFunctionArray.length; iPriceInject++){
						var thisInject = window.injectedPriceFunctionArray[iPriceInject];
						thisPricing = thisInject(thisPricing);
					}
				}
			}
		}catch(e){}
		return (
			<div className='price-wrapper' >
				{
					thisPricing ?
						<div data-catentry={thisPricing.catentryId} data-saledept={thisPricing.dept} data-salemic={thisPricing.mic} data-salestyle={thisPricing.style} data-onspecial={thisPricing.onSpecial || '0'}>
							{
								!thisPricing.onSpecial || thisPricing.onSpecial == 0 ?
									<React.Fragment >
										<If test={thisPricing.regularPriceMessage} >
											<SetInnerHTML className={`${thisPricing.injectedClass ? thisPricing.injectedClass : ''}`} innerHTML={thisPricing.regularPriceMessage} />
										</If>
										<PriceConvert priceOne={thisPricing.offerPriceMin} priceTwo={thisPricing.offerPriceMax} />
										{
											this.props.showFreeShipping ?
												<span className="shipping-message hide-when-international">
													<svg role='img' aria-labelledby='freeShippingIcon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49.15 33.54">
														<title id='freeShippingIcon'>shipping</title>
														<g data-name="Layer 2">
															<g data-name="Layer 1">
																<path d="M45.55,10H39.21V3.61A3.61,3.61,0,0,0,35.61,0h-32A3.61,3.61,0,0,0,0,3.61V24.73a3.61,3.61,0,0,0,3.61,3.61h.46a5.32,5.32,0,0,1,10.63,0h17a5.32,5.32,0,0,1,10.63,0h3.19a3.61,3.61,0,0,0,3.61-3.61V13.6A3.61,3.61,0,0,0,45.55,10Zm1.54,8.41H40v-6H44.9a2.19,2.19,0,0,1,2.19,2.19Z"
																/>
																<path d="M14.06,28.26a4.74,4.74,0,1,0-4.18,5.25A4.74,4.74,0,0,0,14.06,28.26ZM9.35,31.48A2.69,2.69,0,1,1,12,28.8,2.69,2.69,0,0,1,9.35,31.48Z"
																/>
																<path d="M41.75,28.26a4.74,4.74,0,1,0-4.18,5.25A4.74,4.74,0,0,0,41.75,28.26ZM37,31.48a2.69,2.69,0,1,1,2.69-2.69A2.69,2.69,0,0,1,37,31.48Z"
																/>
															</g>
														</g>
													</svg>
													SHIPS FREE <span className="font-italic">- Exclusions Apply</span>
												</span>
											: ''
										}
									</React.Fragment>
									:
									<React.Fragment>

										<SetInnerHTML className={`reduced-copy ${thisPricing.injectedClass ? thisPricing.injectedClass : ''}`} innerHTML={(thisPricing.onSpecialMessageQV != null && thisPricing.onSpecialMessageQV != '' && this.props.qv) ? thisPricing.onSpecialMessageQV :  thisPricing.onSpecialMessage} />
										{
											!thisPricing.hidePrices ?
												<React.Fragment>
													<div className='original-price'>
														{thisPricing.originalPriceMessage} <PriceConvert priceOne={thisPricing.listPriceMin} priceTwo={thisPricing.listPriceMax} />
													</div>
													{
														thisPricing.markDown == 'B' ?
															<div className='was-price'>
																Was: <PriceConvert priceOne={thisPricing.wasPriceMin} priceTwo={thisPricing.wasPriceMax} />
															</div>
															:
															''
													}
													<div className='now-price'>
														Now <PriceConvert priceOne={thisPricing.offerPriceMin} priceTwo={thisPricing.offerPriceMax} />
													</div>
													{
														this.props.showFreeShipping ?
															<span className="shipping-message hide-when-international">
															<svg role='img' aria-labelledby='freeShippingIcon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49.15 33.54">
																<title id='freeShippingIcon'>shipping</title>
																<g data-name="Layer 2">
																	<g data-name="Layer 1">
																		<path d="M45.55,10H39.21V3.61A3.61,3.61,0,0,0,35.61,0h-32A3.61,3.61,0,0,0,0,3.61V24.73a3.61,3.61,0,0,0,3.61,3.61h.46a5.32,5.32,0,0,1,10.63,0h17a5.32,5.32,0,0,1,10.63,0h3.19a3.61,3.61,0,0,0,3.61-3.61V13.6A3.61,3.61,0,0,0,45.55,10Zm1.54,8.41H40v-6H44.9a2.19,2.19,0,0,1,2.19,2.19Z"
																		/>
																		<path d="M14.06,28.26a4.74,4.74,0,1,0-4.18,5.25A4.74,4.74,0,0,0,14.06,28.26ZM9.35,31.48A2.69,2.69,0,1,1,12,28.8,2.69,2.69,0,0,1,9.35,31.48Z"
																		/>
																		<path d="M41.75,28.26a4.74,4.74,0,1,0-4.18,5.25A4.74,4.74,0,0,0,41.75,28.26ZM37,31.48a2.69,2.69,0,1,1,2.69-2.69A2.69,2.69,0,0,1,37,31.48Z"
																		/>
																	</g>
																</g>
															</svg>
															SHIPS FREE <span className="font-italic">- Exclusions Apply</span>
															</span>
														: ''
													}
												</React.Fragment>
											:
											''
										}
									</React.Fragment>

							}
						</div>
						:
						<PriceConvert {...this.props} />
				}
			</div>
		)
	}
}

class PriceConvert extends Component {
	currencySymbol(second) {
		if (typeof window == 'undefined' || window.DILLARDS.country == 'US') {
			return '$';
		} else {
			if (second) return '';
			return window.DILLARDS.currency + ' ';
		}
	}
	roundUp(price, roundMethod) {
		var multiple = Math.pow(10, roundMethod);
		return Math.round(price * multiple) / multiple;
	}
	priceConvert(priceIn) {
		if (typeof window == 'undefined' || window.DILLARDS.country == 'US' || window.dillardsIntlObj.countryCode()[window.DILLARDS.country] === undefined) {
			return priceIn.toFixed(2);
		} else {
			var countryObj = window.dillardsIntlObj.countryCode()[window.DILLARDS.country],
				currencyObj = window.dillardsIntlObj.currencyCode()[window.DILLARDS.currency],
				flc = countryObj.getFLC,
				currencyCode = currencyObj.getCurrencyCode,
				exchangeRate = currencyObj.getExchangeRate,
				roundMethod = currencyObj.getRoundMethod,
				toConvert = priceIn * exchangeRate * flc,
				convertedValue = (roundMethod < 0) ? parseFloat(this.roundUp(toConvert, roundMethod)) : parseFloat(toConvert).toFixed(roundMethod);
			return convertedValue;
		}
	}
	getPriceAsNumber(priceIn) {
		
		let p;
		
		if ( priceIn && typeof(priceIn) !== 'undefined' && !isNaN( (priceIn + '').split('$').join('').split(',').join('').trim() ) ) {
			p = (priceIn + '').split('$').join('').split(',').join('') * 1;
		}

		return p;
	}
	render() {

        let inputPrice = this.getPriceAsNumber(this.props.price);
		let inputPriceOne = this.getPriceAsNumber(this.props.priceOne);
		let inputPriceTwo = this.getPriceAsNumber(this.props.priceTwo);

		let output_price = "";
		let output_role = "";
		let output_classname = "price";
			
		if (isNaN(inputPrice) && !isNaN(inputPriceOne) && !isNaN(inputPriceTwo) && inputPriceOne == inputPriceTwo) {
			inputPrice = inputPriceOne;
		}
				
		if ( this.props.sale && typeof(this.props.sale) !== 'undefined' && this.props.sale == 'true' ) {
			output_classname = "price saleRed";
		}

		if ( !isNaN(inputPrice) ) {			
			output_price = this.currencySymbol() + this.priceConvert(inputPrice).toString();
		} else if ( !isNaN(inputPriceOne) && !isNaN(inputPriceTwo) ) {
			output_price = this.currencySymbol() + this.priceConvert(inputPriceOne).toString() + " - " + this.currencySymbol(true) + this.priceConvert(inputPriceTwo).toString();
		} else {
			output_classname = "alert alert-danger";
			output_role = "alert";
			output_price = "This item is not currently available for purchase on Dillards.com";
		}

		if ( !isNaN(inputPrice) ) {			
			return (
	 			<React.Fragment><span className={output_classname} role={output_role}>{output_price}</span></React.Fragment>
			);
		} else if ( !isNaN(inputPriceOne) && !isNaN(inputPriceTwo) ) {
			return (
				<React.Fragment><span className={output_classname} role={output_role}>{output_price}</span></React.Fragment>
		   );
	   	} else {
			return (
				<React.Fragment><div className={output_classname} role={output_role}>{output_price}</div></React.Fragment>		
			);
 		}		
  	}
};


export default Price
export { PriceConvert }
