

export const pausePlayCarousel = () => {
    setTimeout(() => {
        var li = document.querySelector('.pausePlayCarouselWrapper');
        var dotDiv = document.querySelector('.ContentRecommendation .slick-dots');
        if (dotDiv) {
            dotDiv.insertBefore(li, dotDiv.firstChild);
        }
    }, 500);
};

export const handleTippyPausePlay = (isPlaying, setIsPlaying, slider) => {
    if (isPlaying) {
        setIsPlaying(false);
        slider.current.slickPause();
    } else {
        setIsPlaying(true);
        slider.current.slickPlay();
    }
    return isPlaying;
};
