import React from "react";

class EasyListSplitter extends React.Component {
  render() {
    var arrays = [];
    for (var i = 0; i < this.props.columns; i++) {
      arrays.push([]);
    }
    for (var i = 0; i < this.props.children.length; i++) {
      arrays[
        Math.floor(i / (this.props.children.length / this.props.columns))
      ].push(this.props.children[i]);
    }
    return (
      <React.Fragment>
        {arrays.map((array, index) => (
          <ul className={this.props.columnClassName || ""} key={index}>
            {array}
          </ul>
        ))}
      </React.Fragment>
    );
  }
}

export default EasyListSplitter;
