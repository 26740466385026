export const bannerSlickSettings = (carousel, dots, siteFormat) => {
	var settings = {};
	if (carousel == 'chanelhomepageespot-carousel' || carousel == 'carousel-no-arrows-no-dots') {
		settings = {
			arrows: false,
			autoplay: true,
			draggable: false,
			infinite: true,
			speed: 300,
			fade: false,
			autoplaySpeed: 3000,
		};
	} else {
		settings = {
			arrows: true,
			autoplay: true,
			dots: dots,
			draggable: false,
			infinite: true,
			speed: 300,
			fade: false,
			autoplaySpeed: 3000,
			onInit: () => {
				setTimeout(() => {
					if (document.querySelector('#' + carousel) !== null && document.querySelector('.slick-dots .slick-active button') !== null)
						document
							.querySelector('#' + carousel)
							.querySelector('.slick-dots .slick-active button')
							.setAttribute('aria-current', 'true');
											
						var buttons = document.querySelector('#' + carousel).querySelectorAll('.slick-dots button');
						for (var i = 0; i < buttons.length; i++) {
							buttons[i].setAttribute('aria-label',  "Slide " + String((i + 1)));
						}
				}, 0);
			},
			beforeChange: (current, next) => {
				setTimeout(() => {

					if (document.querySelector('#' + carousel) !== null && document.querySelectorAll('.slick-dots button') !== null) {
						var buttons = document.querySelector('#' + carousel).querySelectorAll('.slick-dots button');
						for (var i = 0; i < buttons.length; i++) {
							buttons[i].removeAttribute('aria-current');
						}
						document
							.querySelector('#' + carousel)
							.querySelector('.slick-dots .slick-active button')
							.setAttribute('aria-current', 'true');
					}

					if (document.querySelector('#' + carousel) !== null && document.querySelector('[data-index="' + next + '"]') !== null && document.querySelector('.slick-slide img') !== null) {
						var _img = document
							.querySelector('#' + carousel)
							.querySelector('[data-index="' + next + '"]')
							.querySelector('.slick-slide img');

						var imgUrl = 'data-deskimage';
						var imgClass = 'desktop-img';
						if (siteFormat !== undefined && siteFormat === 'mobile') {
							imgUrl = 'data-mobileimage';
							imgClass = 'mobile-img';
						}
						var _url = _img.getAttribute(imgUrl);
						var src = _img.getAttribute('src');
						var deskmap = _img.getAttribute('data-deskmap');

						if (deskmap !== undefined && deskmap !== null) {
							_img.setAttribute('usemap', deskmap);
						}

						if (typeof src == 'undefined' || src == '' || src == ' ') {
							_img.setAttribute('src', _url);
							_img.style.display = 'block';
							_img.classList.add(imgClass);
						}
					}
				}, 0);
			},
		};
		try {
			if (typeof window != 'undefined') {
				var theUserAgent = navigator.userAgent;
				var userAgentsWeAreLookingFor = [
					'Googlebot',
					'Chrome-Lighthouse',
					'PTST',
					'AdsBot-Google',
					'DuckDuckBot',
					'Slurp',
					'Baiduspider',
					'YandexBot',
					'Facebot',
					'facebookexternalhit',
					'ia_archiver',
					'LinkedInBot',
					'Google Page Speed Insights',
					'Search Console',
					'Sogou web spider',
					'bingbot',
					'Twitterbot',
					'YandexMobileBot',
					'Pingdom.com_bot',
					'SpeedCurve',
				];
				for (var iUA = 0; iUA < userAgentsWeAreLookingFor.length; iUA++) {
					if (theUserAgent.indexOf(userAgentsWeAreLookingFor[iUA]) > -1) {
						//this is a user agent we want not to autoplay
						settings.autoplay = false;
						break;
					}
				}
			}
		} catch (e) {
			// console.log(e);
		}
	}

	return settings;
};
