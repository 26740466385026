import React from 'react';
import { Link } from 'react-router-dom';

export class FourProductCatalogEntryList extends React.Component {
    render() {
        var widgetData = this.props.widgetData;
        return (
            <React.Fragment>
                {
                    widgetData.tileLink ? 
                    <div className='col-sm-6 col-lg-3'>
                        <div className='category-wrapper'>
                        	<Link to={widgetData.tileLink.split('??').join('?').split('&amp;').join('&').split('&facet=').join('|').split("&#034;").join('"')}>
                                <div className='category-name'>
                                    {widgetData.tileName}
                                </div>
                            </Link>
                        </div>
                    </div>
                    :
                    ''
                }
             </React.Fragment>
        );
    }
}

export default FourProductCatalogEntryList;
