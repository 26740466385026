import React from 'react';
import { StoreHomeList } from '../../../../../Shared/Content/StoreHome/StoreHomeList/StoreHomeList';


export class SpotlightSearch extends React.Component {
    render() {
        var widgetData = this.props.widgetData;
        return (
            <div className='SpotlightSearch'>
                 <div className='spotlight-banner' >
                        <img src={widgetData.spotlightBannerSrc} alt={`${widgetData.spotlightCode} spotlight main image`} />
                    </div>
                <div className='col-sm-12'>
                    {
                        widgetData.hOneText ?
                            <h1><center>{widgetData.hOneText}</center></h1>
                        :
                            ''
                    }
                </div>
                <StoreHomeList stores={widgetData.stores} showStateHeading={true} passedAppFunctions={this.props.passedAppFunctions} />
            </div>
        );
    }
}

export default SpotlightSearch;
