import React from 'react';
import { Link } from 'react-router-dom';
import { SetInnerHTML } from '../../../../../../UI/SetInnerHTML/SetInnerHTML';

export class StoreLocationsResultsList extends React.Component {
    render() {
        var widgetData = this.props.widgetData;
        return (
            <div className='StoreLocationsResultsList'>
                {
                    widgetData.stores ? 
                    <div className='col-sm-12' >
                        <div>
                            Store search results
                        </div>
                        {
                            widgetData.stores.map((thisStore, index)=>
                                <div className='col-sm-6' key={thisStore.name + thisStore.city + thisStore.state}>
                                    <div className='col-sm-6'>
                                         <Link to={thisStore.link} ><img src={thisStore.image} /></Link>
                                    </div>
                                    <div className='col-sm-6'>
                                        <div>
                                            <Link to={thisStore.link} >{thisStore.name}</Link>
                                        </div>
                                        <div>
                                            <a href={`https://www.google.com/maps?q=${thisStore.latitude},${thisStore.longitude}`}>
                                                <div>
                                                    {thisStore.address1}
                                                </div>
                                                <div>
                                                    {thisStore.city}, {thisStore.state} {thisStore.zipcode}
                                                </div>
                                            </a>
                                        </div>
                                        <div>
                                            Phone: <a href={`tel:${thisStore.phone}`} >{thisStore.phone}</a>
                                        </div>
                                        <div>
                                            <Link to={thisStore.link} >Store Details</Link>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    :
                    ''
                }
            </div>
        );
    }
}

export default StoreLocationsResultsList;
