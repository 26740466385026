import React from 'react';
import { Link } from 'react-router-dom';
import { SetInnerHTML } from '../../../../../../UI/SetInnerHTML/SetInnerHTML';

export class BreadcrumbTrail extends React.Component {
  
    cleanLink = (link) => {
        if (link.indexOf('/brand/') > -1) {
            link = link.split('/brand/')[1];
            link = `/brand/${link}`;
        }
        return link;
    }

    buildItemSchema(breadcrumb, position){
        return {
            "@type": "ListItem",
            "position": position,
            "item": {
                "@id": `https://www.dillards.com${this.cleanLink(breadcrumb.breadcrumbLink)}`,
                "name": breadcrumb.breadcrumbValue
            }
        };
    }


    render() {
        var widgetData = this.props.widgetData
            ,schemaJSON= {
                "@context": "http://schema.org"
                ,"@type": "BreadcrumbList"
                ,"itemListElement": []
            }
            ,position = 1;
        
            schemaJSON.itemListElement.push(
                this.buildItemSchema({breadcrumbLink:"", "breadcrumbValue":"home"}, position++)
            );

            if(this.props.passedAppFunctions.navState.indexOf('/brand/') > -1){
                schemaJSON.itemListElement.push(
                    this.buildItemSchema({breadcrumbLink:"https://www.dillards.com/c/shopbybrand", "breadcrumbValue":"Shop By Brands"}, position++)
                );
            } else if(this.props.passedAppFunctions.navState.indexOf('/sale-clearance/') > -1){
                var shopByBrandJSON = {breadcrumbLink:"https://www.dillards.com/c/sale-clearance", "breadcrumbValue":"Sale And Clearance"};
                schemaJSON.itemListElement.push(
                    this.buildItemSchema(shopByBrandJSON, position++)
                );
            } else if(this.props.passedAppFunctions.navState.indexOf('/limited-availability/') > -1){
                var shopByBrandJSON = {breadcrumbLink:"https://www.dillards.com/c/limited-availability", "breadcrumbValue":"Limited Availability"};
                schemaJSON.itemListElement.push(
                    this.buildItemSchema(shopByBrandJSON, position++)
                );
            } else if(this.props.passedAppFunctions.navState.indexOf('/clearance-65/') > -1){
                var shopByBrandJSON = {breadcrumbLink:"https://www.dillards.com/c/clearance-65", "breadcrumbValue":"Clearance"};
                schemaJSON.itemListElement.push(
                    this.buildItemSchema(shopByBrandJSON, position++)
                );
            } else if(this.props.passedAppFunctions.navState.indexOf('/clearance-') > -1){
                var shopByBrandJSON = {breadcrumbLink:`https://www.dillards.com/c/${this.props.passedAppFunctions.navState.split('//')[2]}` , "breadcrumbValue":`${this.props.passedAppFunctions.navState.split('//')[2]}`};
                schemaJSON.itemListElement.push(
                    this.buildItemSchema(shopByBrandJSON, position++)
                );
            }
            for(var i = 0; i < widgetData.breadcrumbItems.length; i++){
                schemaJSON.itemListElement.push(this.buildItemSchema(widgetData.breadcrumbItems[i], position++));
            }

        return (
                <React.Fragment>
                    <nav aria-label='breadcrumbs'>
                        <ul className='breadCrumb'>
                            {
                                widgetData.breadcrumbItems.map((thisBreadcrumb, index) =>
                                    <li key={index + '' + thisBreadcrumb.breadcrumbValue}>
                                        <Link to={this.cleanLink(thisBreadcrumb.breadcrumbLink)} >
                                            <div>
                                                <SetInnerHTML innerHTML={thisBreadcrumb.breadcrumbValue} />
                                            </div>
                                        </Link>
                                    </li>
                                )
                            }
                        </ul>
                    </nav>
                    <If test={widgetData.breadcrumbItems.length > 0}>
                        <script type="application/ld+json"
                            suppressHydrationWarning={true} 
                            dangerouslySetInnerHTML={{__html: JSON.stringify(schemaJSON)}}>
                        </script>
                    </If>
                </React.Fragment>
        );
    }
}

export default BreadcrumbTrail;
