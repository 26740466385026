import React from 'react';
import { Link } from 'react-router-dom';
import { Search } from '../../../../../Desktop/Header/Search/Search';

export class SearchForm extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            noSearchResultsFound: true
        }
    }

    render() {
        var widgetData = this.props.widgetData;
        return (
            <div className='SearchForm col-sm-12'>
            <h3>Try a different search term:</h3>
                <Search
                    passedAppFunctions={this.props.passedAppFunctions}
                    noSearchResults = {this.state.noSearchResults}
                    />
            </div>
        );
    }
}

export default SearchForm;
