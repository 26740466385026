import React from 'react';
import { Link } from 'react-router-dom';
import { SetInnerHTML } from '../../../../../../UI/SetInnerHTML/SetInnerHTML';

export class SearchSummary extends React.Component {
    render() {
        var widgetData = this.props.widgetData;
        return (
            <div className='SearchSummary'>
                {
                    widgetData.noResultsTerm ?
                    <div className='noSearchResults'>
                        Oops! Sorry, no results for 
                    <svg xmlns="http://www.w3.org/2000/svg"  style={{margin:'0 5px'}} width="15" height="15" viewBox="0 0 16.59 12.63">
                        <title>quote-left</title>
                        <g data-name="Layer 2">
                            <g data-name="Layer 1">
                                <path d="M5.89.53c-3,2.21-3.17,5.23-2.4,5.33a3.71,3.71,0,0,1,2.3,1,3.31,3.31,0,0,1,0,4.75,3.36,3.36,0,0,1-4.8,0c-2-2-.72-5.86.67-8.07A9.56,9.56,0,0,1,5.46,0Zm9.8,0c-3,2.21-3.17,5.23-2.4,5.33a3.71,3.71,0,0,1,2.3,1,3.36,3.36,0,1,1-4.75,4.75c-2.06-2-.77-5.86.67-8.07A9.23,9.23,0,0,1,15.26,0Z" />
                            </g>
                        </g>
                    </svg>
                       <SetInnerHTML className="d-inline" innerHTML={widgetData.noResultsTerm}/>
                    <svg xmlns="http://www.w3.org/2000/svg"  style={{margin:'0 5px'}} width="15" height="15" viewBox="0 0 16.59 12.63">
                        <title>quote-right</title>
                        <g data-name="Layer 2">
                            <g data-name="Layer 1">
                                <path d="M10.7,12.1c3-2.21,3.17-5.23,2.4-5.33a3.71,3.71,0,0,1-2.3-1,3.31,3.31,0,0,1,0-4.75,3.36,3.36,0,0,1,4.8,0c2,2,.72,5.86-.67,8.07a9.56,9.56,0,0,1-3.74,3.55Zm-9.8,0c3-2.21,3.17-5.23,2.4-5.33a3.71,3.71,0,0,1-2.3-1A3.39,3.39,0,0,1,.95,1a3.36,3.36,0,0,1,4.8,0c2.06,2,.77,5.86-.67,8.07a9.23,9.23,0,0,1-3.74,3.55Z" />
                            </g>
                        </g>
                    </svg>
                    </div>
                    :
                    ''
                }
                 {
                     widgetData.noResultsTerm && widgetData.suggestions?
                        <div>
                            You might try this search:
                            <ul className='suggestedSearchTerms'>
                            {widgetData.suggestions.map((suggestion, index) =>
                                <li key={suggestion}><Link key="index" to={`/search-term/${suggestion.split(' ').join('+')}`}>{suggestion}</Link></li>
                            )}
                            </ul>
                        </div>
                    :
                    ''
                 }
                {
                    widgetData.resultsTag ?
                    <div>
                        Products tagged as "{widgetData.resultsTag}"
                    </div>
                    :
                    ''
                }
            </div>
        );
    }
}

export default SearchSummary;
