import React from 'react';
import { Link } from 'react-router-dom';
import { SetInnerHTML } from '../../../../../../UI/SetInnerHTML/SetInnerHTML';

export class HOneHeader extends React.Component {
	render() {
		var widgetData = this.props.widgetData;
		return (
			<Choose>
				<When test={this.props.passedAppFunctions.siteFormat == 'desktop'}>
					<div className='HOneHeader col'>
						<div className={`${this.props.containsBreadcrumbTrail == true ? 'col-10 offset-2 p-0' : 'col-12'}`}>
							{<SetInnerHTML innerHTML={widgetData.hOneText} /> ? (
								<h1>
									<SetInnerHTML innerHTML={widgetData.hOneText} />
								</h1>
							) : (
								''
							)}
						</div>
					</div>
				</When>
				<Otherwise>
					<div className='HOneHeader col'>
						{<SetInnerHTML innerHTML={widgetData.hOneText} /> ? (
							<h1>
								<SetInnerHTML innerHTML={widgetData.hOneText} />
							</h1>
						) : (
							''
						)}
					</div>
				</Otherwise>
			</Choose>
		);
	}
}

export default HOneHeader;
