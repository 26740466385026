import React from 'react';
import { Link } from 'react-router-dom';
import { SetInnerHTML } from '../../../../../../UI/SetInnerHTML/SetInnerHTML';
import { SharedCategoryNavigation } from '../../../../../Shared/Content/Layouts/Widgets/CategoryNavigation/CategoryNavigation';
import { generateLinkForFacetCommon } from '../../../../../../../util/generateLinkForFacet';
import { FacetNavigation } from '../FacetNavigation/FacetNavigation';
import { ShopSale } from '../ShopSale/ShopSale';
import { addCurrentParamsToCategoryLinkCommon } from '../../../../../../../util/addCurrentParamsToCategoryLink';

export class CategoryNavigation extends SharedCategoryNavigation {
	constructor(props) {
		super(props);
		this.state = {
			categoryOpen: false,
			brandOpen: false,
		};
		this.state.storedFacet = this.props.passedAppFunctions.getURLParamValue('facet');
		this.state.storedOrderBy = this.props.passedAppFunctions.getURLParamValue('orderBy');
		if (this.props.widgetData.categoryBrands && this.props.widgetData.categoryBrands.length > 0) {
			this.state.cachedBrandsToUse = this.props.widgetData.categoryBrands;
		} else if (this.props.facetsJSON && this.props.facetsJSON['mfName_ntk_cs']) {
			this.state.cachedBrandsToUse = this.props.facetsJSON['mfName_ntk_cs'].values;
		} else {
			this.state.cachedBrandsToUse = [];
		}
		this.state.brandsToUse = this.state.cachedBrandsToUse;
		this.state.searchTerm = '';
		this.handleBrandSearch = this.handleBrandSearch.bind(this);
		this.clearSearchTerm = this.clearSearchTerm.bind(this);
		this.categoryToggle = this.categoryToggle.bind(this);
		this.brandToggle = this.brandToggle.bind(this);
		this.categoryClicked = this.categoryClicked.bind(this);
		this.vertFacetClicked = this.vertFacetClicked.bind(this);
	}

	shouldComponentUpdate(nextProps, nextState) {
		var returnValue = false;
		if (nextProps.passedAppFunctions.quickviewIsOpen) {
			return false;
		}
		if (nextState.searchTerm != this.state.searchTerm) {
			returnValue = true;
		}
		if (
			!_.isEqual(nextProps.passedAppFunctions.seoColorInfo, this.props.passedAppFunctions.seoColorInfo) ||
			this.props.passedAppFunctions.getURLParamValue('facet') != this.state.storedFacet
		) {
			this.state.storedFacet = this.props.passedAppFunctions.getURLParamValue('facet');
			returnValue = true;
		}
		if (this.props.passedAppFunctions.getURLParamValue('orderBy') != this.state.storedOrderBy) {
			this.state.storedOrderBy = this.props.passedAppFunctions.getURLParamValue('orderBy');
			returnValue = true;
		}
		if (this.props.passedAppFunctions.getURLParamValue('facet') ? (this.props.passedAppFunctions.getURLParamValue('facet').length == 0 ? true : false) : true) {
			returnValue = true;
		}		
		return returnValue || !_.isEqual(this.props.widgetData, nextProps.widgetData);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.passedAppFunctions.windowPosition != this.props.passedAppFunctions.windowPosition) {
			this.props.passedAppFunctions.setNavigationToggle('keepPos', true);
			window.scrollTo(0, this.props.passedAppFunctions.windowPosition);
		}
	}

	componentWillUnmount() {
		if (this.props.widgetData.orientation == 'vertical') {
			this.props.passedAppFunctions.setNavigationToggle('keepPos', false);
			this.props.passedAppFunctions.setWindowPosition('windowPosition', 0);
			document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
			document.body.classList.remove('filter-open');
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.widgetData.categoryBrands && nextProps.widgetData.categoryBrands.length > 0) {
			this.state.cachedBrandsToUse = nextProps.widgetData.categoryBrands;
		} else if (nextProps.facetsJSON && nextProps.facetsJSON['mfName_ntk_cs']) {
			this.state.cachedBrandsToUse = nextProps.facetsJSON['mfName_ntk_cs'].values;
		} else {
			this.state.cachedBrandsToUse = [];
		}
		this.state.brandsToUse = [];
		for (var i = 0; i < this.state.cachedBrandsToUse.length; i++) {
			var thisBrand = this.state.cachedBrandsToUse[i];
			if (thisBrand.brandLabel && thisBrand.brandLabel.replace(/\s/g, '').search(new RegExp(this.state.searchTerm.replace(/\s/g, ''), 'i')) > -1) {
				this.state.brandsToUse.push(thisBrand);
			}
			if (thisBrand.dsp && thisBrand.dsp.replace(/\s/g, '').search(new RegExp(this.state.searchTerm.replace(/\s/g, ''), 'i')) > -1) {
				this.state.brandsToUse.push(thisBrand);
			}
		}
		this.setState({});
	}
	handleBrandSearch(event) {
		var newSearchTerm = event.target.value;
		//filter brands based on search term
		this.state.brandsToUse = [];
		for (var i = 0; i < this.state.cachedBrandsToUse.length; i++) {
			var thisBrand = this.state.cachedBrandsToUse[i];
			if (thisBrand.brandLabel && thisBrand.brandLabel.replace(/\s/g, '').search(new RegExp(newSearchTerm.replace(/\s/g, ''), 'i')) > -1) {
				this.state.brandsToUse.push(thisBrand);
			}
			if (thisBrand.dsp && thisBrand.dsp.replace(/\s/g, '').search(new RegExp(newSearchTerm.replace(/\s/g, ''), 'i')) > -1) {
				this.state.brandsToUse.push(thisBrand);
			}
		}
		this.setState({ searchTerm: newSearchTerm });
	}
	vertFacetClicked(thisBrand) {
		this.props.passedAppFunctions.setWindowPosition('windowPosition', window.pageYOffset);
		var facetBreadcrumbForTracking = this.props.widgetData.facetBreadcrumbForTracking;
		var isFacetAlreadySelected = this.isFacetSelected(
			thisBrand.val
				.split('%2B')
				.join('%25252B')
				.split('%26')
				.join('%2526')
		);
		if (facetBreadcrumbForTracking && !isFacetAlreadySelected) {
			try {
				//adobe tracking
				trackFacetUsage('brand', thisBrand.dsp);
			} catch (err) {}
		}
	}
	clearSearchTerm(event) {
		this.state.brandsToUse = JSON.parse(JSON.stringify(this.state.cachedBrandsToUse));
		this.setState({ searchTerm: '' });
	}
	generateNewArrivalsLink(facetValue) {
		var returnLink = this.props.passedAppFunctions.routeInfo.location.pathname.split('?')[0];
		var search = this.props.passedAppFunctions.routeInfo.location.search || '';
		if (this.props.passedAppFunctions.routeInfo.location.pathname.indexOf('?') > -1) {
			search =
				'?' +
				this.props.passedAppFunctions.routeInfo.location.pathname
					.split('?')
					.splice(1)
					.join('?');
		}
		return this.props.passedAppFunctions.updateUrlWithParams(returnLink + search, { facet: facetValue, pageNumber: false });
	}
	addCurrentParamsToCategoryLink(inputUrl) {
		return addCurrentParamsToCategoryLinkCommon(inputUrl, this.props);
	}
	generateLinkForFacet(thisFacetValue) {
		return generateLinkForFacetCommon(thisFacetValue, this.props);
	}
	isFacetSelected(passedFacet) {
		var returnBool = false;
		var currentSelectedFacets = this.props.passedAppFunctions.getURLParamValue('facet');
		if (currentSelectedFacets) {
			var currentSelectedFacetsSplit = currentSelectedFacets.split('|');
			for (var i = 0; i < currentSelectedFacetsSplit.length; i++) {
				var thisFacet = currentSelectedFacetsSplit[i];
				if (thisFacet == passedFacet) {
					return true;
				}
			}
		}
		return returnBool;
	}
	categoryToggle() {
		if (this.props.passedAppFunctions.siteFormat == 'tablet') {
			this.setState({ categoryOpen: !this.state.categoryOpen });
		}
	}
	brandToggle() {
		if (this.props.passedAppFunctions.siteFormat == 'tablet') {
			this.setState({ brandOpen: !this.state.brandOpen });
		}
	}
	categoryClicked() {
		this.props.passedAppFunctions.siteFormat == 'tablet' ? (this.props.toggleFacetMenu(), this.categoryToggle()) : '';
	}
	thisFacetClicked = (event) => {
		if (event.key === 'Enter' || event.key === ' ') {
			event.target.parentNode.lastChild.click();
		}
	};

	render() {
		var widgetData = this.props.widgetData;
		return (
			<div className='CategoryNavigation'>
				<div>
					<If test={!this.props.newArrivalsWidgetIsPresent && this.props.facetsJSON.dil_newArrival}>
						<fieldset>
							<legend className='sr-only'>New Arrivals</legend>
							<ul className='facet facet-header no-style'>
								<li id='newArrivalsChkBox' className='ShopNewArrivals'>
									<div className='custom-control custom-checkbox'>
										<input
											className='custom-control-input'
											id='newArrivalsChkBox'
											checked={this.isFacetSelected(this.props.facetsJSON.dil_newArrival.values[0].val)}
											readOnly
											type='checkbox'
											role='checkbox'
											aria-checked={this.isFacetSelected(this.props.facetsJSON.dil_newArrival.values[0].val)}
											onKeyUp={this.thisFacetClicked}
										/>
										<label
											className='custom-control-label-nocolor custom-control-label'
											htmlFor='newArrivalsChkBox'
											onClick={(e) => e.target.nextElementSibling.click()}
										>
											<span className='sr-only'>New Arrivals</span>
										</label>
										<Link
											to={this.generateNewArrivalsLink(this.props.facetsJSON.dil_newArrival.values[0].val)}
											manual_cm_sp={this.props.widgetData.newArrivals_manualCmSp}
											tabIndex='-1'
										>
											New Arrivals
										</Link>
									</div>
								</li>
							</ul>
						</fieldset>
					</If>
					<If
						test={
							!this.props.shopSaleWidgetIsPresent &&
							this.props.passedAppFunctions.navState.indexOf('sale-clearance') == -1 &&
							this.props.passedAppFunctions.navState.indexOf('limited-availability') == -1 &&
							this.props.passedAppFunctions.navState.indexOf('clearance-') == -1 &&
							(this.props.facetsJSON.dil_pricereduced || (widgetData.isSaleItem ? (widgetData.isSaleItem === 'true' ? true : false) : false))
						}
					>
						<ShopSale passedAppFunctions={this.props.passedAppFunctions} {...this.props} />
					</If>
				</div>
				{widgetData.categories && widgetData.categories.length > 0 && (!widgetData.isLeafLevel || widgetData.isLeafLevel !== 'true') ? (
					<React.Fragment>
						<div className='filter-list'>
							<h3
								className={`${
									this.props.passedAppFunctions.siteFormat == 'tablet' &&
									Object.keys(this.props.facetsJSON).length == 0 &&
									!this.props.widgetData.categoryBrands
										? 'open'
										: ''
								} facet-title`}
								onClick={this.categoryToggle}
							>
								Category
							</h3>
							<ul className='facet'>
								{widgetData.categories.map((thisCategory, index) => (
									<li
										data-leftnavlink={this.addCurrentParamsToCategoryLink(thisCategory.categoryLink)}
										key={index + '' + thisCategory.categoryValue}
										onClick={this.categoryClicked}
									>
										<Link
											manual_cm_sp={thisCategory.manualCmSp}
											rel={this.addCurrentParamsToCategoryLink(thisCategory.categoryLink).indexOf('?') > -1 ? 'nofollow' : undefined}
											to={this.addCurrentParamsToCategoryLink(thisCategory.categoryLink)}
										>
											<div>
												<SetInnerHTML innerHTML={thisCategory.categoryLabel} />
											</div>
										</Link>
									</li>
								))}
							</ul>
						</div>
					</React.Fragment>
				) : (
					''
				)}
				<If test={this.state.cachedBrandsToUse.length > 1}>
					<div className='filter-list'>
						<div className='vert-facets'>
                            <h3 className={`${this.state.brandOpen ? 'open' : ''} facet-title`} onClick={this.brandToggle}>Brand</h3>
							<If test={this.props.passedAppFunctions.siteFormat == 'desktop'}>
								<div className='searchByBrandFilterWrapper'>
								<span aria-describedby='searchByBrandFilterInput' className="sr-only">When typing in this field, a list of search results will appear and be automatically updated as you type.</span>
									<input
										id='searchByBrandFilterInput'
										placeholder='Search by brand...'
										type='text'
										autoComplete='off'
										autoCorrect='off'
										spellCheck='false'
										value={this.state.searchTerm}
										onChange={this.handleBrandSearch}
									/>
									<Choose>
										<When test={this.state.searchTerm}>
											<span onClick={this.clearSearchTerm}>
												<svg className='clearBrand' height='15' width='15' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48.77 48.77'>
													<title>Clear</title>
													<g data-name='Layer 2'>
														<g data-name='Layer 1'>
															<path d='M25.8,24.39,48.48,1.71A1,1,0,0,0,47.07.29L24.39,23,1.71.29A1,1,0,0,0,.29,1.71L23,24.39.29,47.07a1,1,0,1,0,1.41,1.41L24.39,25.8,47.07,48.48a1,1,0,0,0,1.41-1.41Z' />
														</g>
													</g>
												</svg>
											</span>
										</When>
										<Otherwise>
											<span>
												<svg height='15' width='15' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48.92 47.58'>
													<title>search</title>
													<g data-name='Layer 2'>
														<g data-name='Layer 1'>
															<path d='M48.6,45.85,32.28,30.5a18.56,18.56,0,1,0-1.4,1.43L47.23,47.31a1,1,0,1,0,1.37-1.46ZM2,18.42A16.42,16.42,0,1,1,18.42,34.83,16.44,16.44,0,0,1,2,18.42Z' />
														</g>
													</g>
												</svg>
											</span>
										</Otherwise>
									</Choose>
								</div>
							</If>
							<fieldset>
								<legend className='sr-only'>Brand</legend>
								<ul className='facet'>
									<ForEach var='thisBrand' items={this.state.brandsToUse}>
										<li key={thisBrand.dsp + thisBrand.brandLabel} className={`${thisBrand.dsp ? 'custom-control custom-checkbox' : 'facet-link'}`}>
											<Choose>
												<When test={thisBrand.dsp}>
													<input
														className='custom-control-input'
														readOnly
														checked={this.isFacetSelected(thisBrand.val.split('%2B').join('%25252B').split('%26').join('%2526').split('%25252B').join('%252B').split('%C2%AE').join('%25C2%25AE'))}
														onKeyUp={this.thisFacetClicked}
														type='checkbox'
														role='checkbox'
														aria-checked={this.isFacetSelected(thisBrand.val.split('%2B').join('%25252B').split('%26').join('%2526').split('%25252B').join('%252B').split('%C2%AE').join('%25C2%25AE'))}
														id={thisBrand.val}
														tabIndex='0'
													/>
													<label
														className='custom-control-label-nocolor custom-control-label'
														htmlFor={thisBrand.val}
														onClick={(e) => e.target.nextElementSibling.click()}
													>
														<span className='sr-only'>{thisBrand.dsp}</span>
													</label>
													<Link
														onClick={() => this.vertFacetClicked(thisBrand)}
														rel='nofollow'
														to={this.generateLinkForFacet(thisBrand.val.split('%2B').join('%25252B').split('%26').join('%2526').split('%25252B').join('%252B').split('%C2%AE').join('%25C2%25AE'))}
														tabIndex='-1'
													>
														{thisBrand.dsp}
													</Link>
												</When>
												<Otherwise>
													<Link to={thisBrand.brandLink}>
														<span>
															<SetInnerHTML innerHTML={thisBrand.brandLabel} />
														</span>
													</Link>
												</Otherwise>
											</Choose>
										</li>
									</ForEach>
								</ul>
							</fieldset>
						</div>
					</div>
				</If>
				<If test={this.props.passedAppFunctions.siteFormat == 'desktop'}>
					<div>
						<FacetNavigation
							widgetData={widgetData}
							passedAppFunctions={this.props.passedAppFunctions}
							facetsJSON={this.props.facetsJSON}
							facetsInCatNav={true}
							excludeFacets={this.props.excludeFacets}
						/>
					</div>
				</If>
				<script
					type='application/ld+json'
					suppressHydrationWarning={true}
					dangerouslySetInnerHTML={{ __html: JSON.stringify(this.buildStructuredData(widgetData.categories)) }}
				></script>
			</div>
		);
	}
}

export default CategoryNavigation;
