import React from 'react';
import { Link } from 'react-router-dom';
import { SetInnerHTML } from '../../../../../../UI/SetInnerHTML/SetInnerHTML';

export class ChanelCategoryNavigation extends React.Component {
    render() {
        var widgetData = this.props.widgetData;

        return (
            
            <div className='chanelNav'>
                {
                    widgetData.isChanelHome === 'true' && widgetData.currentCategoryName == '' ?
                    ''
                    :
                    <div>
                    <Link className='navigateBack' to={widgetData.parentCatGroupUrl}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.39 48.77">
                            <title>arrow-left</title>
                            <g data-name="Layer 2">
                                <g data-name="Layer 1">
                                    <path d="M24.39,48.77a1,1,0,0,1-.71-.29L.29,25.09a1,1,0,0,1,0-1.41L23.68.29a1,1,0,0,1,1.41,1.41L2.41,24.39,25.09,47.07a1,1,0,0,1-.71,1.71Z" />
                                </g>
                            </g>
                        </svg>
                    View All {widgetData.parentCatGroupName}
                    </Link>
                    <h5 className='chanelCurrentCat'><SetInnerHTML innerHTML={widgetData.currentCategoryName} /></h5>
                    </div>
                }
                {
                    widgetData.categories && widgetData.categories.length > 0 ?
                        <React.Fragment>
                            <ul className='chanelNav__list'>
                                {
                                    widgetData.categories.map((thisCategory, index) =>
                                        <li className='chanelNav__category' key={index + '' + thisCategory.categoryName}>
                                            <Link className='chanelNav__link' to={decodeURIComponent(thisCategory.categoryLink)} >
                                                <div>
                                                    <SetInnerHTML innerHTML={thisCategory.categoryName} />
                                                </div>
                                            </Link>
                                        </li>
                                    )
                                }
                            </ul>
                        </React.Fragment>
                    :
                        ''
                }
            </div>
        )
    }
}