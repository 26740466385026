import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import Slider from 'react-slick';
import { SetInnerHTML } from '../../../../../../UI/SetInnerHTML/SetInnerHTML';
import { htmlUnescape } from '../../../../../../../util/htmlUnescape';
import { resizeMap } from '../../../../../../../util/imageMaps';
import { pausePlayCarousel, handleTippyPausePlay } from '../../../../../../../util/pausePlayHandlers';
import { removeImgSkeleton } from '../../../../../../../util/removeImgSkeleton';
import { faqsInit } from '../../../../../../../util/faqs';
import { syndeca, isCatalogPage } from '../../../../../../../util/syndeca';
import { bannerSlickSettings } from '../../../../../../../util/slickCarouselSettings';
import { pollyFillStartsWith } from '../../../../../../../util/polyfills';
import getTopPersonalizationInfo from '../../../../../../../util/getTopPersonalizationInfo';
import { wellsfargoSidebarTitle } from '../../../../../../../util/wellsfargoMobileMenu';

export const ContentRecommendation = (props) => {
	const { passedAppFunctions, resultListIsPresent, slotNumber, widgetData } = props;
	const slider = useRef();
	const [isLoading, setIsLoading] = useState(true);
	const [checkCarouselId, setCheckCarouselId] = useState([]);
	const [imageState, setImageState] = useState({ containsLazyImg: false, containsImgNoLazy: false });
	const [superMap, setSuperMap] = useState({});
	const [styleState, setStyleState] = useState([]);
	const [carouselItems, setCarouselItems] = useState([]);
	const [sliderSettings, setSliderSettings] = useState();
	const [isPlaying, setIsPlaying] = useState(true);
	const carouselIds = ['homepage-carousel', 'carousel-no-arrows', 'carousel-no-arrows-no-dots', 'carousel-example-generic', 'chanelhomepageespot-carousel'];
	const carouselId = carouselIds.filter((element) => htmlUnescape(widgetData.content).indexOf(`id="${element}"`) > -1);
	var hideThisContent =
		(slotNumber == 9 || slotNumber == 8) &&
		(passedAppFunctions.seoColorInfo.seoColorValue ||
			passedAppFunctions.getURLParamValue('pageNumber') ||
			(passedAppFunctions.getURLParamValue('facet') &&
				passedAppFunctions.getURLParamValue('facet') != 'dil_shipinternational%3AY' &&
				passedAppFunctions.getURLParamValue('facet') != 'dil_shipinternational:Y'));
	resultListIsPresent !== undefined && resultListIsPresent != 'true' ? (hideThisContent = false) : '';

	useEffect(() => {
		setIsLoading(true);
		contentCheck();
		removeImgSkeleton();
		pausePlayCarousel();
		faqsInit(passedAppFunctions);
		syndeca(widgetData);
		wellsfargoSidebarTitle();
		removeDuplicateSlide();
		setTimeout(() => {
			resizeMap();
			addAspectRatio();
			document.getElementById('mobileContainer') !== null ? document.getElementById('mobileContainer').remove() : '';
			window.scrollBy(0, 1);
			window.scrollBy(0, -1);
			document.getElementById('modal-brafit-btn') !== null ? prepIfBraFitGuide() : '';
		}, 600);
		setTimeout(() => {
			passedAppFunctions.lazyObserver();
			document.querySelector('.getInspired') !== null ? document.querySelector('.getInspired').classList.remove('getInspired') : '';
		}, 1000);

		return () => {
			if (document.getElementById(carouselId[0]) == null) {
				setCarouselItems([]);
			}

			setCheckCarouselId([]);
			if (isCatalogPage(widgetData)) {
				try {
					window.removeEventListener('message', window.receiveSyndecaMessage, false);
					if (window.location.href.indexOf('/c/catalogs') == -1) {
						var syndecaScript = document.getElementById('syndeca-embed-script');
						if(syndecaScript != null) {
							syndecaScript.remove();
						}
						window.receiveSyndecaMessage = false;
					}
				} catch (e) {}
			}
			setTimeout(() => {
				resizeMap();
			}, 600);
		};
	}, [props]);

	const removeDuplicateSlide = () => {
		setTimeout(() => {
			let slickTrackChildren = $('[data-espotname="GetInspired"]').siblings().find('.productRecs.splashProdRecs .slick-track').children();
			if (slickTrackChildren.length > 0) {
				for (let x = 0; x < slickTrackChildren.length; x++) {
					if (slickTrackChildren[x].style.width.indexOf('%') > -1) {
						slickTrackChildren[x].remove();
					}
				}
			}
		}, 1000);
	};

	const addAspectRatio = () => {
		if (window.IntersectionObserver) {
			document.querySelectorAll('img.lazy-extended').forEach((lazyImage) => {
				var imgSrc = lazyImage.dataset.original;
				var parameters = '';
				if (imgSrc !== null && imgSrc !== undefined) {
					parameters = imgSrc.split('?');
				}
				if (parameters.length > 1) {
					var urlParams = new URLSearchParams(parameters[1]);
					var w = '';
					var h = '';
					if (urlParams.has('w')) {
						w = urlParams.get('w');
					} else if (urlParams.has('wid')) {
						w = urlParams.get('wid');
					}
					if (urlParams.has('h')) {
						h = urlParams.get('h');
					} else if (urlParams.has('hei')) {
						h = urlParams.get('hei');
					}
					if (w > '' && h > '') {
						var dim = [w, h];
						lazyImage.parentNode.style.setProperty('padding-top', `calc(${dim[1]} / ${dim[0]} * 100%)`);
						if (!lazyImage.parentNode.classList.contains('lazy-extended-wrapper')) {
							lazyImage.parentNode.classList.add('lazy-extended-wrapper');
						}
					}
				}
			});
		}
	};

	const fetchPersonalizedData = async (superMap, winningSuper) => {
		await axios(
			`/webapp/wcs/stores/servlet/NonCacheESpot?storeId=301&langId=-1&catalogId=301&emsName=${superMap[`data-espotpersonalize${winningSuper}`]}`
		).then((res) => {
			if (document.getElementById(carouselId[0]) === null) {
				prepIfCarouselContent(res.data.JSON.eSpotContent0);
			} else {
				prepIfCarouselContent('');
			}
		});
	};

	const checkIfPersonalizedContent = () => {
		//[410-Womens][420-Beauty][425-Shoes][430-Juniors][435-Accessories][440-Kids][445-Mens][450-Home][470-Handbags][480-Lingerie]
		const superEntriesToLoop = ['420', '425', '430', '435', '440', '445', '450', '470', '480'];
		var supersWeNeedToLookAt = ['410'];
		var personalizationDataAttrSupers = superEntriesToLoop.filter(
			(element) => htmlUnescape(widgetData.content).indexOf('data-espotpersonalize' + element) > -1
		);
		//If content is personalized, if not move on to prepIfCarouselContent()
		if (personalizationDataAttrSupers !== undefined && personalizationDataAttrSupers.length > 0) {
			personalizationDataAttrSupers.map((getSuper) => {
				var tempArr = htmlUnescape(widgetData.content).split(`data-espotpersonalize${getSuper}="`).pop();
				var tempAttrValue = tempArr.split('"')[0];
				superMap['data-espotpersonalize' + getSuper] = `${tempAttrValue}`;
				supersWeNeedToLookAt.push(getSuper);
			});
			try {
				var topPersonalizationInfo = getTopPersonalizationInfo(supersWeNeedToLookAt);
				var winningSuper = topPersonalizationInfo.favoriteSuperCat || '410';
				//Content contains the personalized data attr,
				//however they user does not have a personalized value in their localstorage,
				//or the already have the default value of 410 aka 'Womens'.
				if (winningSuper !== '410') {
					fetchPersonalizedData(superMap, winningSuper);
				} else {
					prepIfCarouselContent();
				}
			} catch (e) {
				console.log(e);
			}
		} else {
			//Content does not contain the personalized data attr
			prepIfCarouselContent();
		}
	};

	const prepIfCarouselContent = (result = '') => {
		let content = '';
		result !== '' ? (content = result) : (content = htmlUnescape(widgetData.content));
		try {
			if (carouselId != undefined && carouselId.length !== 0 && content.indexOf('<div class="item">') >= 0) {
				setCarouselItems([]);
				setCheckCarouselId(carouselId);
				var items = content.split('<div class="item">');
				pollyFillStartsWith();
				for (var i = 0; i < items.length; i++) {
					if (
						items[i].trim().startsWith('<a ') ||
						items[i].trim().startsWith('<img ') ||
						(items[i].trim().startsWith('<div ') && items[i].trim().indexOf(carouselId) === -1)
					) {
						if (i === items.length - 1) {
							var numExtras = items[i].split('<').length - 1 - (items[i].split('</').length - 1);
							var indicies = [],
								j = -1;
							while ((j = items[i].indexOf('</', j + 1)) >= 0) {
								indicies.push(j);
							}
							let itemIndicies = items[i].substring(0, indicies[indicies.length - numExtras]);
							setCarouselItems((carouselItems) => [...carouselItems, itemIndicies]);
						} else {
							let itemSubstring = items[i].substring(0, items[i].lastIndexOf('</div>'));
							setCarouselItems((carouselItems) => [...carouselItems, itemSubstring]);
						}
					} else if (items[i].startsWith('<style>')) {
						var stripTag = items[i].split('<style>').pop().split('</style>')[0];
						setStyleState(stripTag);
					}
				}
				var getSliderSettings = bannerSlickSettings(carouselId, true, passedAppFunctions.siteFormat);
				setSliderSettings(getSliderSettings);
				//Carousel content ready, move to render.
				setIsLoading(false);
			} else {
				if (document.getElementById(carouselId[0]) !== null) {
				} else {
					setIsLoading(false);
				}
				//Content is not a carousel, move to render.
			}
		} catch (e) {
			console.log(e);
		}
	};
	const prepIfBraFitGuide = () => {
		document.getElementById('modal-brafit-btn').addEventListener('click', function () {
			var braInnerHtml = document.getElementById('modal-brafit-content');
			var response = {};
			response.modalType = 'BraFitGuide';
			response.data = braInnerHtml;
			passedAppFunctions.setModalInfo(response);
		});
	};
	const contentCheck = () => {
		if (htmlUnescape(widgetData.content).indexOf('lazy-extended') > -1) {
			setImageState({ ...imageState, containsLazyImg: true });
			passedAppFunctions.lazyObserver();
			//Content contains our .lazy-extended class reserved for the extended part of the page, which means no carousel... move to render.
			setIsLoading(false);
		} else {
			setImageState({ ...imageState, containsLazyImg: false });
			if (htmlUnescape(widgetData.content).indexOf('src=') > -1) {
				setImageState({ ...imageState, containsImgNoLazy: true });
				//Check if these images are personalized on a carousel.
				checkIfPersonalizedContent();
			} else {
				setImageState({ ...imageState, containsImgNoLazy: false });
				//Content contains no images, which means no carousel...load content to state and move to render.
				setIsLoading(false);
			}
		}
	};

	return (
		<>
			{!isLoading && !hideThisContent ? (
				carouselItems.length > 0 &&
				carouselId.length !== 0 &&
				passedAppFunctions.navState !== '/chanel/c/men-fragrance' &&
				passedAppFunctions.navState !== '/chanel/c/women-fragrance' ? (
					<div role='region' aria-label='hero-carousel' className={`ContentRecommendation ${carouselId}`}>
						<div id={carouselId} {...superMap}>
							{(styleState || []).map((thisStyle, i) => (
								<style key={i} dangerouslySetInnerHTML={{ __html: thisStyle }} />
							))}
							<Slider {...sliderSettings} ref={slider}>
								{(carouselItems || []).map((thisItem, i) => {
									return <div suppressHydrationWarning={true} key={i} dangerouslySetInnerHTML={{ __html: thisItem }} />;
								})}
							</Slider>
							{carouselId != 'chanelhomepageespot-carousel' ? (
								<li className='pausePlayCarouselWrapper' style={{ display: 'none' }}>
									<button
										className='button pausePlayCarousel'
										type='button'
										onClick={() => {
											handleTippyPausePlay(isPlaying, setIsPlaying, slider);
										}}
									>
										{isPlaying ? (
											<svg
												className='pauseButtonTippy'
												role='img'
												aria-labelledby='pauseButton'
												xmlns='http://www.w3.org/2000/svg'
												viewBox='0 0 45 47'
												width='10'
											>
												<title id='pauseButton'>pause promo</title>
												<g data-name='Layer 2'>
													<g data-name='Layer 1'>
														<rect width='17' height='47' rx='2.2' />
														<rect x='28' width='17' height='47' rx='2.2' />
													</g>
												</g>
											</svg>
										) : (
											<svg
												className='playButtonTippy'
												role='img'
												aria-labelledby='playButton'
												xmlns='http://www.w3.org/2000/svg'
												viewBox='0 0 42.43 48.31'
												width='10'
											>
												<title id='playButton'>play promo</title>
												<g data-name='Layer 2'>
													<g data-name='Layer 1'>
														<path d='M2.63.46,42,23.06a1.54,1.54,0,0,1,0,2.19L2.63,47.86A1.54,1.54,0,0,1,0,46.76V1.55A1.54,1.54,0,0,1,2.63.46Z' />
													</g>
												</g>
											</svg>
										)}
									</button>
								</li>
							) : (
								<></>
							)}
						</div>
					</div>
				) : (
					<SetInnerHTML
						className={`${
							widgetData.content != undefined && htmlUnescape(widgetData.content).indexOf('category-container') !== -1
								? 'col-sm-6 col-lg-3'
								: '' || (widgetData.content != undefined && htmlUnescape(widgetData.content).indexOf('homepage-carousel') !== -1)
								? ''
								: ''
						}`}
						containsLazyImg={imageState.containsLazyImg}
						containsImgNoLazy={imageState.containsImgNoLazy}
						widgetType={`${props.widgetData.widgetType != undefined ? props.widgetData.widgetType : ''}`}
						innerHTML={htmlUnescape(widgetData.content)}
					/>
				)
			) : isLoading && !hideThisContent ? (
				<>
					<div
						className={`${
							props.passedAppFunctions.siteFormat == 'desktop'
								? 'skeletonContentCrew'
								: props.passedAppFunctions.siteFormat == 'tablet'
								? 'skeletonTabletCrew'
								: ''
						}`}
					></div>
				</>
			) : (
				''
			)}
		</>
	);
};
