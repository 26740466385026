import React from 'react';
import { Link } from 'react-router-dom';

export class ShopByPriceReduction extends React.Component {
    render() {
        var widgetData = this.props.widgetData;
        return (
            <div className='ShopByPriceReduction col-sm-12' id='ShopByPriceReduction'>
                {
                    widgetData.pageTitle == 'Last Chance' ? 
                        <div className='header-area last-chance'>
                            <h1>
                                CLEARANCE - <span className='reduced-copy'>65% off</span> and higher!<span className='reduced-span'>Hurry up! Deals like this won't last for long.</span>
                            </h1>
                        </div>
                        :
                        <div className={`header-area ${widgetData.pageFacet == 'limited' ? 'limitedAvail' : ''}`}>
                            <h1>{widgetData.pageTitle}</h1>
                        </div>
                }
                {
                    widgetData.pageFacet == 'limited' ?
                    <h4 className='text-center m-25'><strong>Act fast!</strong> We only have a few left of these popular items. Don't miss out - shop our limited availability products now.</h4>
                    :
                    ''

                }
                <div className='row categories'>
                    {
                        widgetData.buttons.map((thisButton, index)=>
                            <div className='col-sm-6 col-md-6 col-lg-3' key={index}>
                                <Link to={thisButton.buttonLink} >
                                    <div className='category-wrapper'>
                                        <div className='category-name'>{thisButton.buttonLabel}</div>
                                        <div className='category-results'>Shop All {thisButton.buttonCount} results
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.39 48.77">
                                            <title>arrow-right</title>
                                            <g data-name="Layer 2">
                                                <g data-name="Layer 1">
                                                    <path d="M1,48.77a1,1,0,0,1-.71-1.71L23,24.39.29,1.71A1,1,0,0,1,1.71.29L25.09,23.68a1,1,0,0,1,0,1.41L1.71,48.48A1,1,0,0,1,1,48.77Z" />
                                                </g>
                                            </g>
                                        </svg>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        )
                    }
                </div>
            </div>
        );
    }
}

export default ShopByPriceReduction;
