import React from 'react';
import { HorizontalRecommendation } from '../../../../../Shared/Content/Layouts/Widgets/HorizontalRecommendation/HorizontalRecommendation';
import { SetInnerHTML } from '../../../../../../UI/SetInnerHTML/SetInnerHTML';
import { getTopPersonalizationInfo } from '../../../../../../../util/getTopPersonalizationInfo';

export class ProductRecommendations extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			attribute: '',
			noPersonalization: false,
		};
	}
	componentDidMount() {
		if (this.props.widgetData.zone == 'BrandSuperCatNewArrivals' || this.props.widgetData.zone == 'SuperCatNewArrivals' || this.props.widgetData.zone == 'BrandSuperCatPopular') {
			if (this.props.widgetData.superCategory !== undefined) {
				var topPersonalizationInfo = getTopPersonalizationInfo([this.props.widgetData.superCategory]);				
				this.setState({
					superCat: topPersonalizationInfo.favoriteSuperCat,
					mfName: topPersonalizationInfo.favoriteBrand,
					noPersonalization: topPersonalizationInfo.favoriteSuperCat.length > 0,
				});								
			} else {						
				var topPersonalizationInfo = getTopPersonalizationInfo('ALL');
				this.setState({
					superCat: topPersonalizationInfo.favoriteSuperCat,
					mfName: topPersonalizationInfo.favoriteBrand,
					noPersonalization: this.props.widgetData.zone == 'BrandSuperCatPopular' ? true : topPersonalizationInfo.favoriteSuperCat.length > 0,
				});
			}
		}
	}
	getRecAttributes = (recAttr) => {
		this.setState({ rec_attributes: recAttr });
	};

	render() {
		if (this.state.rec_attributes > 0) {
			this.props.passedAppFunctions.lazyObserver();
		}
		return (
			<If
				test={
					((this.props.widgetData.zone != 'BrandSuperCatNewArrivals' && this.props.widgetData.zone != 'SuperCatNewArrivals' && this.props.widgetData.zone != 'BrandSuperCatPopular') || this.state.noPersonalization) &&
					this.props.passedAppFunctions.wasParms.useProductRecommendations == 'true'
				}
			>				<Choose>
					<When test={this.props.widgetData.productsPerRow == '6'}>
						<div className='row'>
							<If test={this.props.widgetData.topContentName && this.state.rec_attributes > 0}>
								<SetInnerHTML
									innerHTML={this.props.widgetData.topContent}
									espotname={this.props.widgetData.topContentName}
									className='col-sm-12 topContent p-0'
								/>
							</If>
							<div className='col-sm-12'>
								<HorizontalRecommendation
									{...this.props.widgetData}
									attribute={this.state.attribute}
									passedAppFunctions={this.props.passedAppFunctions}
									contentData={this.props.contentData}
									getRecAttributes={this.getRecAttributes}
									mfName={this.state.mfName}
									superCat={this.state.superCat}
								/>
							</div>
							<If test={this.props.widgetData.bottomContentName && this.state.rec_attributes > 0}>
								<SetInnerHTML
									innerHTML={this.props.widgetData.bottomContent}
									espotname={this.props.widgetData.bottomContentName}
									className='col-sm-12 bottomContent'
								/>
							</If>
						</div>
					</When>

					<When test={this.props.widgetData.productsPerRow == '5'}>
						<div className='row'>
							<If test={this.props.widgetData.topContentName && this.state.rec_attributes > 0}>
								<SetInnerHTML innerHTML={this.props.widgetData.topContent} espotname={this.props.widgetData.topContentName} className='col-sm-12 topContent' />
							</If>
							<If test={this.props.widgetData.leftContentName && this.state.rec_attributes > 0}>
								<SetInnerHTML
									innerHTML={this.props.widgetData.leftContent}
									espotname={this.props.widgetData.leftContentName}
									className='col-sm-3 leftContent'
								/>
							</If>
							<div className='col-sm-9'>
								<HorizontalRecommendation
									{...this.props.widgetData}
									attribute={this.state.attribute}
									passedAppFunctions={this.props.passedAppFunctions}
									contentData={this.props.contentData}
									getRecAttributes={this.getRecAttributes}
									mfName={this.state.mfName}
									superCat={this.state.superCat}
								/>
							</div>
							<If test={this.props.widgetData.rightContentName && this.state.rec_attributes > 0}>
								<SetInnerHTML
									innerHTML={this.props.widgetData.rightContent}
									espotname={this.props.widgetData.rightContentName}
									className='col-sm-3 rightContent'
								/>
							</If>
							<If test={this.props.widgetData.bottomContentName && this.state.rec_attributes > 0}>
								<SetInnerHTML
									innerHTML={this.props.widgetData.bottomContent}
									espotname={this.props.widgetData.bottomContentName}
									className='col-sm-12 bottomContent'
								/>
							</If>
						</div>
					</When>

					<When test={this.props.widgetData.productsPerRow == '4'}>
						<div className='row'>
							<If test={this.props.widgetData.topContentName && this.state.rec_attributes > 0}>
								<SetInnerHTML innerHTML={this.props.widgetData.topContent} espotname={this.props.widgetData.topContentName} className='col-sm-12 topContent' />
							</If>
							<If test={this.props.widgetData.leftContentName && this.state.rec_attributes > 0}>
								<SetInnerHTML
									innerHTML={this.props.widgetData.leftContent}
									espotname={this.props.widgetData.leftContentName}
									className='col-sm-5 leftContent'
								/>
							</If>
							<div className='col-sm-7'>
								<HorizontalRecommendation
									{...this.props.widgetData}
									attribute={this.state.attribute}
									passedAppFunctions={this.props.passedAppFunctions}
									contentData={this.props.contentData}
									getRecAttributes={this.getRecAttributes}
									mfName={this.state.mfName}
									superCat={this.state.superCat}
								/>
							</div>
							<If test={this.props.widgetData.rightContentName && this.state.rec_attributes > 0}>
								<SetInnerHTML
									innerHTML={this.props.widgetData.rightContent}
									espotname={this.props.widgetData.rightContentName}
									className='col-sm-5 rightContent'
								/>
							</If>
							<If test={this.props.widgetData.bottomContentName && this.state.rec_attributes > 0}>
								<SetInnerHTML
									innerHTML={this.props.widgetData.bottomContent}
									espotname={this.props.widgetData.bottomContentName}
									className='col-sm-12 bottomContent'
								/>
							</If>
						</div>
					</When>

					<When test={this.props.widgetData.productsPerRow == '3'}>
						<div className={`row ${this.props.widgetData.leftContentName == 'GetInspired' || this.props.widgetData.rightContentName == 'GetInspired' ? 'getInspired' : ''}`}>
							<If test={this.props.widgetData.topContentName && this.state.rec_attributes > 0}>
								<SetInnerHTML innerHTML={this.props.widgetData.topContent} espotname={this.props.widgetData.topContentName} className='col-sm-12 topContent' />
							</If>
							<If test={this.props.widgetData.leftContentName && this.state.rec_attributes > 0}>
								<SetInnerHTML
									innerHTML={this.props.widgetData.leftContent}
									espotname={this.props.widgetData.leftContentName}
									className='col-sm-6 leftContent'
								/>
							</If>
							<div className='col-sm-6'>
								<HorizontalRecommendation
									{...this.props.widgetData}
									attribute={this.state.attribute}
									passedAppFunctions={this.props.passedAppFunctions}
									contentData={this.props.contentData}
									getRecAttributes={this.getRecAttributes}
									mfName={this.state.mfName}
									superCat={this.state.superCat}
								/>
							</div>
							<If test={this.props.widgetData.rightContentName && this.state.rec_attributes > 0}>
								<SetInnerHTML
									innerHTML={this.props.widgetData.rightContent}
									espotname={this.props.widgetData.rightContentName}
									className='col-sm-6 rightContent'
								/>
							</If>
							<If test={this.props.widgetData.bottomContentName && this.state.rec_attributes > 0}>
								<SetInnerHTML
									innerHTML={this.props.widgetData.bottomContent}
									espotname={this.props.widgetData.bottomContentName}
									className='col-sm-12 bottomContent'
								/>
							</If>
						</div>
					</When>
					{/* 2 */}
					<When test={this.props.widgetData.productsPerRow == '2'}>
						<div className='row'>
							<If test={this.props.widgetData.topContentName && this.state.rec_attributes > 0}>
								<SetInnerHTML innerHTML={this.props.widgetData.topContent} espotname={this.props.widgetData.topContentName} className='col-sm-12 topContent' />
							</If>
							<If test={this.props.widgetData.leftContentName && this.state.rec_attributes > 0}>
								<SetInnerHTML
									innerHTML={this.props.widgetData.leftContent}
									espotname={this.props.widgetData.leftContentName}
									className='col-sm-8 leftContent'
								/>
							</If>
							<div className='col-sm-4'>
								<HorizontalRecommendation
									{...this.props.widgetData}
									attribute={this.state.attribute}
									passedAppFunctions={this.props.passedAppFunctions}
									contentData={this.props.contentData}
									getRecAttributes={this.getRecAttributes}
									mfName={this.state.mfName}
									superCat={this.state.superCat}
								/>
							</div>
							<If test={this.props.widgetData.rightContentName && this.state.rec_attributes > 0}>
								<SetInnerHTML
									innerHTML={this.props.widgetData.rightContent}
									espotname={this.props.widgetData.rightContentName}
									className='col-sm-8 rightContent'
								/>
							</If>
							<If test={this.props.widgetData.bottomContentName && this.state.rec_attributes > 0}>
								<SetInnerHTML
									innerHTML={this.props.widgetData.bottomContent}
									espotname={this.props.widgetData.bottomContentName}
									className='col-sm-12 bottomContent'
								/>
							</If>
						</div>
					</When>

					<When test={this.props.widgetData.productsPerRow == '1'}>
						<div className='row'>
							<If test={this.props.widgetData.topContentName && this.state.rec_attributes > 0}>
								<SetInnerHTML innerHTML={this.props.widgetData.topContent} espotname={this.props.widgetData.topContentName} className='col-sm-12 topContent' />
							</If>
							<If test={this.props.widgetData.leftContentName && this.state.rec_attributes > 0}>
								<SetInnerHTML
									innerHTML={this.props.widgetData.leftContent}
									espotname={this.props.widgetData.leftContentName}
									className='col-sm-9 leftContent'
								/>
							</If>
							<div className='col-sm-3'>
								<HorizontalRecommendation
									{...this.props.widgetData}
									attribute={this.state.attribute}
									passedAppFunctions={this.props.passedAppFunctions}
									contentData={this.props.contentData}
									getRecAttributes={this.getRecAttributes}
									mfName={this.state.mfName}
									superCat={this.state.superCat}
								/>
							</div>
							<If test={this.props.widgetData.rightContentName && this.state.rec_attributes > 0}>
								<SetInnerHTML
									innerHTML={this.props.widgetData.rightContent}
									espotname={this.props.widgetData.rightContentName}
									className='col-sm-9 rightContent'
								/>
							</If>
							<If test={this.props.widgetData.bottomContentName && this.state.rec_attributes > 0}>
								<SetInnerHTML
									innerHTML={this.props.widgetData.bottomContent}
									espotname={this.props.widgetData.bottomContentName}
									className='col-sm-12 bottomContent'
								/>
							</If>
						</div>
					</When>
				</Choose>
			</If>
		);
	}
}

export default ProductRecommendations;
