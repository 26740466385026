import React from 'react';
import Portal from '../../../../../../UI/Portal/Portal';
import { Link } from 'react-router-dom';
import { SharedCatalogEntryList } from '../../../../../Shared/Content/Layouts/Widgets/CatalogEntryList/CatalogEntryList';
import CatalogEntryListTile from './CatalogEntryListTile';
import { ArrowDownIcon } from '../../../../../../UI/Icons/Icons';
import { CatalogEntryListStyleHeight } from '../../../../../Shared/Content/Layouts/Widgets/CatalogEntryList/CatalogEntryListStyleHeight';

export class CatalogEntryList extends SharedCatalogEntryList {
	constructor(props) {
		super(props);
		this.state.sortOpen = false;
	}
	render() {
		return (
			<div className='CatalogEntryList col p-0'>
				
				 <script
					type='application/ld+json'
					suppressHydrationWarning={true}
					dangerouslySetInnerHTML={{ __html: JSON.stringify(this.buildStructuredDataForProductList()) }}></script> 

				<CatalogEntryListStyleHeight passedAppFunctions={this.props.passedAppFunctions} />
				
				<div className='d-flex w-100'>
					<Choose>
						<When test={this.props.passedAppFunctions.siteFormat == 'desktop'}>
							<div className='col-5 sortBy'>
								<label htmlFor='catalog-entry-list-sort'>Sort By </label>
								<select
									id='catalog-entry-list-sort'
									onChange={this.updateOrderBy}
									value={
										(this.props.passedAppFunctions.getURLParamValue('orderBy') || this.props.passedAppFunctions.wasParms.defaultNavSort) == '1' &&
										(this.props.passedAppFunctions.getURLParamValue('emphasize') || '').indexOf('$') == 0
											? '0'
											: this.props.passedAppFunctions.getURLParamValue('orderBy') || '1'
									}>
									<option value='1'>Popular items</option>
									<If
										test={
											this.props.passedAppFunctions.generatePersonalizationParam() &&
											this.props.passedAppFunctions.wasParms.isPersonalizationOn == 'true' &&
											this.props.passedAppFunctions.navState.indexOf('search-term') == -1
										}>
										<option value='0'>Just for you</option>
									</If>
									<Choose>
										<When
											test={
												this.props.passedAppFunctions.navState.indexOf('/c/sale-clearance') > -1 ||
												this.props.passedAppFunctions.navState.indexOf('/c/clearance-') > -1
											}>
											<option value='7'>Price Low to High</option>
											<option value='8'>Price High to Low</option>
										</When>
										<Otherwise>
											<option value='3'>Price Low to High</option>
											<option value='4'>Price High to Low</option>
										</Otherwise>
									</Choose>
									<option value='5'>Top rated</option>
									<option value='6'>Newest</option>
									<If
										test={
											this.props.passedAppFunctions.navState.indexOf('/c/sale-clearance') == -1 &&
											this.props.passedAppFunctions.navState.indexOf('/c/clearance-') == -1
										}>
										<option value='9'>Sale</option>
									</If>
								</select>
							</div>
						</When>
						<Otherwise>
							<TabletSort
								toggleFacetMenu={this.props.toggleFacetMenu}
								toggleSortMenu={this.props.toggleSortMenu}
								passedAppFunctions={this.props.passedAppFunctions}
								updateOrderBy={this.updateOrderBy}
							/>
						</Otherwise>
					</Choose>

					<div className='col-7 p-r-0 pagination__wrapper d-flex align-items-center justify-content-end' id='topPagination'>
						<Pagination {...this.props} generatePaginationLink={this.generatePaginationLink} />
					</div>
				</div>
				<div className='row tilesWrapper'>
					<TileArea passedAppFunctions={this.props.passedAppFunctions} widgetData={this.props.widgetData} actualBreadCrumb={this.props.actualBreadCrumb}/>
				</div>
				<div className='col pagination__wrapper d-flex align-items-center justify-content-end' id='bottomPagination'>
					<Pagination
						{...this.props}
						generatePaginationLink={this.generatePaginationLink}
						currentPage={(this.props.passedAppFunctions.getURLParamValue('pageNumber') || '1') * 1}
					/>
				</div>
			</div>
		);
	}
}

class TileArea extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.state.storedIsInternational = this.props.passedAppFunctions.isInternational();
	}
	shouldComponentUpdate(nextProps, nextState) {
		var returnValue = false;
		if (this.props.passedAppFunctions.isInternational() != this.state.storedIsInternational) {
			this.state.storedIsInternational = this.props.passedAppFunctions.isInternational();
			returnValue = true;
		}
		if (nextProps.passedAppFunctions.quickviewIsOpen) {
			return false;
		}
		return returnValue || !_.isEqual(this.props.widgetData, nextProps.widgetData);
	}
	render() {
		return (
			<React.Fragment>
				<Choose>
					<When test={this.props.widgetData.products && this.props.widgetData.products.length > 0}>
						<ForEach items={this.props.widgetData.products} var='thisProduct' index='productIndex'>
							<CatalogEntryListTile
								lastTile={productIndex == this.props.widgetData.products.length - 1}
								passedAppFunctions={this.props.passedAppFunctions}
								siteFormat={this.props.passedAppFunctions.siteFormat}
								thisProduct={thisProduct}
								key={thisProduct.catentryId}
								productIndex={productIndex}
								actualBreadCrumb={this.props.actualBreadCrumb}
								currentPage={this.props.currentPage}
							/>
						</ForEach>
					</When>
					<Otherwise>
						<div className='col-sm-12 col-sm-12 col-md-12 col-lg-12'>
							<div className='alert alert-danger nav-alert m-t-15' role='alert'>
								No products were found that match this criteria.
							</div>
						</div>
					</Otherwise>
				</Choose>
			</React.Fragment>
		);
	}
}

class TabletSort extends React.Component {
	constructor(props) {
		super(props);
		this.state = { sortOpen: false };

		this.toggleSort = this.toggleSort.bind(this);
		this.handleFilterClose = this.handleFilterClose.bind(this);
	}
	toggleSort() {
		this.props.toggleSortMenu();
		this.setState({ sortOpen: !this.state.sortOpen });
	}
	handleFilterClose() {
		this.toggleSort();
		this.props.passedAppFunctions.setNavigationToggle('keepPos', false);
		this.props.passedAppFunctions.setNavigationToggle('filterOpen', false);
		this.props.passedAppFunctions.setWindowPosition('windowPosition', 0);
		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;
		document.body.classList.remove('filter-open');
	}
	render() {
		return (
			<React.Fragment>
				<div className='col-5 filter-sort-row'>
					<div className='row'>
						<div className='col p-l-0 p-r-5'>
							<button className='btn btn-grey btn-filter' type='button' onClick={this.props.toggleFacetMenu}>
								Filter
								<i className='icon i-arrow-down'>
									<span className='sr-only'>Down Arrow</span>
								</i>
							</button>
						</div>
						<div className='col p-r-0 p-l-5'>
							<button className='btn btn-grey btn-sort' type='button' onClick={this.toggleSort}>
								Sort
								<i className='icon i-arrow-down'>
									<span className='sr-only'>Down Arrow</span>
								</i>
							</button>
						</div>
					</div>
				</div>
				<Portal>
					<div className={`SortPortal ${this.state.sortOpen == true ? 'opened' : ''}`}>
						<aside className={`sort-by-wrapper filter-sort-wrappers ${this.state.sortOpen == true ? 'opened' : ''}`}>
							<div className='sort-by-content filter-sort-contents'>
								<div className='header-wrapper'>
									<button className='btn btn-secondary' type='button' onClick={this.handleFilterClose}>
										Done
									</button>
								</div>
								<ul className='sort-list reset-list'>
									<li className='facet-title' data-value='1' onClick={this.props.updateOrderBy}>
										Popular items
										<If
											test={
												(this.props.passedAppFunctions.getURLParamValue('orderBy') || this.props.passedAppFunctions.wasParms.defaultNavSort) == '1' &&
												(this.props.passedAppFunctions.getURLParamValue('emphasize') || '').indexOf('$') != 0
											}>
											<div className='icon i-check-mark'>
												<span className='sr-only'>Check Mark</span>
											</div>
										</If>
									</li>
									<If
										test={this.props.passedAppFunctions.wasParms.isPersonalizationOn == 'true' && this.props.passedAppFunctions.generatePersonalizationParam()}>
										<li className='facet-title' data-value='0' onClick={this.props.updateOrderBy}>
											Just For You
											<If
												test={
													(this.props.passedAppFunctions.getURLParamValue('orderBy') || this.props.passedAppFunctions.wasParms.defaultNavSort) == '1' &&
													(this.props.passedAppFunctions.getURLParamValue('emphasize') || '').indexOf('$') == 0
												}>
												<div className='icon i-check-mark'>
													<span className='sr-only'>Check Mark</span>
												</div>
											</If>
										</li>
									</If>
									<li className='facet-title' data-value='3' onClick={this.props.updateOrderBy}>
										Price Low to High
										<If
											test={
												(this.props.passedAppFunctions.getURLParamValue('orderBy') || this.props.passedAppFunctions.wasParms.defaultNavSort) == '3' ||
												(this.props.passedAppFunctions.getURLParamValue('orderBy') || this.props.passedAppFunctions.wasParms.defaultNavSort) == '7'
											}>
											<div className='icon i-check-mark'>
												<span className='sr-only'>Check Mark</span>
											</div>
										</If>
									</li>
									<li className='facet-title' data-value='4' onClick={this.props.updateOrderBy}>
										Price High to Low
										<If
											test={
												(this.props.passedAppFunctions.getURLParamValue('orderBy') || this.props.passedAppFunctions.wasParms.defaultNavSort) == '4' ||
												(this.props.passedAppFunctions.getURLParamValue('orderBy') || this.props.passedAppFunctions.wasParms.defaultNavSort) == '8'
											}>
											<div className='icon i-check-mark'>
												<span className='sr-only'>Check Mark</span>
											</div>
										</If>
									</li>
									<li className='facet-title' data-value='5' onClick={this.props.updateOrderBy}>
										Top rated
										<If test={(this.props.passedAppFunctions.getURLParamValue('orderBy') || this.props.passedAppFunctions.wasParms.defaultNavSort) == '5'}>
											<div className='icon i-check-mark'>
												<span className='sr-only'>Check Mark</span>
											</div>
										</If>
									</li>
									<li className='facet-title' data-value='6' onClick={this.props.updateOrderBy}>
										Newest
										<If test={(this.props.passedAppFunctions.getURLParamValue('orderBy') || this.props.passedAppFunctions.wasParms.defaultNavSort) == '6'}>
											<div className='icon i-check-mark'>
												<span className='sr-only'>Check Mark</span>
											</div>
										</If>
									</li>
									<If
										test={
											this.props.passedAppFunctions.navState.indexOf('/c/sale-clearance') == -1 &&
											this.props.passedAppFunctions.navState.indexOf('/c/clearance-') == -1
										}>
										<li className='facet-title' data-value='9' onClick={this.props.updateOrderBy}>
											Sale
											<If test={(this.props.passedAppFunctions.getURLParamValue('orderBy') || this.props.passedAppFunctions.wasParms.defaultNavSort) == '9'}>
												<div className='icon i-check-mark'>
													<span className='sr-only'>Check Mark</span>
												</div>
											</If>
										</li>
									</If>
								</ul>
							</div>
						</aside>
						<div className='shade' onClick={this.toggleSort}></div>
					</div>
				</Portal>
			</React.Fragment>
		);
	}
}

class Pagination extends React.Component {
	resetKeepPos = () => {
		this.props.passedAppFunctions.setNavigationToggle('keepPos', false);
	};

	render() {
		var pageSize = (this.props.passedAppFunctions.getURLParamValue('pageSize') || '96') * 1;
		var totalPages = Math.ceil(this.props.widgetData.productCount / pageSize);
		var currentPage = (this.props.passedAppFunctions.getURLParamValue('pageNumber') || '1') * 1;
		return (
			<nav aria-label='Product navigation'>
				<ul className='pagination'>
					<li className='page-item'>
						<span className='itemCount'>{this.props.widgetData.productCount} Items</span>
					</li>
					<If test={totalPages > 1}>
						<React.Fragment>
							<If test={currentPage != '1'}>
								<React.Fragment>
									<li className='page-item' data-value='1'>
										<Link
											className='page-link pagination__first'
											rel={this.props.generatePaginationLink(1).indexOf('facet=') > -1 ? 'nofollow' : undefined}
											to={this.props.generatePaginationLink(1)}>
											First
										</Link>
									</li>
									<li className='page-item' data-value={currentPage - 1}>
										<Link
											className='page-link'
											rel={this.props.generatePaginationLink(currentPage - 1).indexOf('facet=') > -1 ? 'nofollow' : undefined}
											to={this.props.generatePaginationLink(currentPage - 1)}>
											<div className='icon i-arrow-left'>
												<span className='sr-only'>Previous Page</span>
											</div>
										</Link>
									</li>
								</React.Fragment>
							</If>
							<If test={currentPage == totalPages && currentPage > 2}>
								<li className='page-item' data-value={currentPage - 2}>
									<Link
										className='page-link'
										rel={this.props.generatePaginationLink(currentPage - 2).indexOf('facet=') > -1 ? 'nofollow' : undefined}
										to={this.props.generatePaginationLink(currentPage - 2)}>
										{currentPage - 2}
									</Link>
								</li>
							</If>
							<If test={currentPage != '1' && currentPage - 1 > 0}>
								<li className='page-item' data-value={currentPage - 1}>
									<Link
										className='page-link'
										rel={this.props.generatePaginationLink(currentPage - 1).indexOf('facet=') > -1 ? 'nofollow' : undefined}
										to={this.props.generatePaginationLink(currentPage - 1)}>
										{currentPage - 1}
									</Link>
								</li>
							</If>
							<li className='pagination__activePage page-item' data-value={currentPage}>
								<span>{currentPage}</span>
							</li>
							<If test={currentPage < totalPages}>
								<React.Fragment>
									<li className='page-item' data-value={currentPage + 1}>
										<Link
											className='page-link'
											onClick={this.resetKeepPos}
											rel={this.props.generatePaginationLink(currentPage + 1).indexOf('facet=') > -1 ? 'nofollow' : undefined}
											to={this.props.generatePaginationLink(currentPage + 1)}>
											{currentPage + 1}
										</Link>
									</li>
									<If test={currentPage == '1' && totalPages > 2}>
										<li className='page-item' data-value={currentPage + 2}>
											<Link
												className='page-link'
												onClick={this.resetKeepPos}
												rel={this.props.generatePaginationLink(currentPage + 2).indexOf('facet=') > -1 ? 'nofollow' : undefined}
												to={this.props.generatePaginationLink(currentPage + 2)}>
												{currentPage + 2}
											</Link>
										</li>
									</If>
									<li className='page-item' data-value={currentPage + 1}>
										<Link
											className='page-link'
											onClick={this.resetKeepPos}
											rel={this.props.generatePaginationLink(currentPage + 1).indexOf('facet=') > -1 ? 'nofollow' : undefined}
											to={this.props.generatePaginationLink(currentPage + 1)}>
											<div className='icon i-arrow-right'>
												<span className='sr-only'>Next Page</span>
											</div>
										</Link>
									</li>
									<li className='page-item p-r-0' data-value={totalPages}>
										<Link
											className='page-link pagination__last'
											onClick={this.resetKeepPos}
											rel={this.props.generatePaginationLink(totalPages).indexOf('facet=') > -1 ? 'nofollow' : undefined}
											to={this.props.generatePaginationLink(totalPages)}>
											Last
										</Link>
									</li>
								</React.Fragment>
							</If>
						</React.Fragment>
					</If>
				</ul>
			</nav>
		);
	}
}

export default CatalogEntryList;
