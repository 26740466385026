import React from 'react';
import { Link } from "react-router-dom";

export class OutfitResults extends React.Component {
    generatePaginationLink (pageNumber) {
        var returnLink = this.props.passedAppFunctions.routeInfo.location.pathname.split('?')[0];
        var search = this.props.passedAppFunctions.routeInfo.location.search || '';
        if (this.props.passedAppFunctions.routeInfo.location.pathname.indexOf('?') > -1) {
            search = '?' + this.props.passedAppFunctions.routeInfo.location.pathname.split('?').splice(1).join('?');
        }
        return this.props.passedAppFunctions.updateUrlWithParams(returnLink + search, { pageNumber: pageNumber == 1 ? false : pageNumber });
    }
    generateProductUrl (thisProduct) {
        return `/p/${thisProduct.nameForURL}/${thisProduct.catentryId}/outfit`;
    }
    numberOfPages () {
        var numberOfPages = 1;
        try {
            return Math.ceil(this.props.widgetData.totalNumberOfOutfits / this.props.widgetData.outfitListPageSize);
        } catch (e) { }
        return numberOfPages;
    }
    shouldHavePreviousLink () {
        var returnBool = false;
        if (this.props.widgetData.pageNumber > 1) {
            returnBool = true;
        }
        return returnBool;
    }
    shouldHaveNextLink () {
        var returnBool = false;
        if ((this.props.widgetData.pageNumber || 1) * 1 < this.numberOfPages()) {
            returnBool = true;
        }
        return returnBool;
    }
    render () {
        return (
            <>
                <div className='CatalogEntryList OutfitResults'>
                    <div className='row'>
                        <Choose>
                            <When test={this.props.widgetData.products && this.props.widgetData.products.length > 0}>
                                <ForEach items={this.props.widgetData.products} var='thisProduct' index='productIndex' >
                                    <div className='col-sm-3 result-tile' key={thisProduct.catentryId}>
                                        <div className='result-tile-above' onMouseEnter={this.handleMouseEnterImage} onMouseLeave={this.handleMouseLeaveImage}>
                                            <Link className='d-block tileImgLinkWrapper' to={{ pathname: this.generateProductUrl(thisProduct), state: { useOldCategoryId: true } }} >
                                                <img src={`https://dimg.dillards.com/is/image/DillardsZoom/nav2/${thisProduct.nameForURL}/${thisProduct.fullImage}.jpg`} alt={thisProduct.originalName} />
                                            </Link>
                                        </div>
                                        <Link className='shopThisOutfit' to={this.generateProductUrl(thisProduct)} title='Shop This Outfit' >
                                            Shop this outfit
                                        </Link>
                                    </div>
                                </ForEach>
                            </When>
                            <Otherwise>
                                <div className='col-sm-12 col-sm-12 col-md-12 col-lg-12'>
                                    <div className='alert alert-danger m-t-15' role='alert'>No products were found that match this criteria.</div>
                                </div>
                            </Otherwise>
                        </Choose>
                    </div>
                </div>
                <div className='outfitPagination'>
                    <div className='row'>
                        <div className='col-sm-6 text-right' >
                            <If test={this.shouldHavePreviousLink()} >
                                <Link to={this.generatePaginationLink((this.props.widgetData.pageNumber || 1) * 1 - 1)} className='btn prevPage'>
                                    <svg role='img' aria-labelledby='previousPageIcon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.39 48.77" height="15px" width="15px">
                                        <title id='previousPageIcon'>previous page</title>
                                        <g data-name="Layer 2">
                                            <g data-name="Layer 1">
                                                <path d="M24.39,48.77a1,1,0,0,1-.71-.29L.29,25.09a1,1,0,0,1,0-1.41L23.68.29a1,1,0,0,1,1.41,1.41L2.41,24.39,25.09,47.07a1,1,0,0,1-.71,1.71Z" />
                                            </g>
                                        </g>
                                    </svg>
                                    previous
                                </Link>
                            </If>
                        </div>
                        <div className='col-sm-6'>
                            <If test={this.shouldHaveNextLink()} >
                                <Link to={this.generatePaginationLink((this.props.widgetData.pageNumber || 1) * 1 + 1)} className='btn nextPage' >
                                    next
                                    <svg role='img' aria-labelledby='nextPageIcon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.39 48.77" height="15px" width="15px">
                                        <title id='nextPageIcon'>next page</title>
                                        <g data-name="Layer 2">
                                            <g data-name="Layer 1">
                                                <path d="M1,48.77a1,1,0,0,1-.71-1.71L23,24.39.29,1.71A1,1,0,0,1,1.71.29L25.09,23.68a1,1,0,0,1,0,1.41L1.71,48.48A1,1,0,0,1,1,48.77Z" />
                                            </g>
                                        </g>
                                    </svg>
                                </Link>
                            </If>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default OutfitResults;
