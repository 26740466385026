import React from 'react';
import { Link } from 'react-router-dom';
import EasyListSplitter from '../../../../../../UI/EasyListSplitter/EasyListSplitter';
import { SearchIcon } from '../../../../../../UI/Icons/Icons';

export class ShopByBrand extends React.Component {
    constructor(props) {
		super(props);
		this.state = {
		};
        this.state.brandsToUse = [];
		this.state.searchTerm = '';
        this.state.showClear = false;
        this.state.brandSearchActive = false;
        this.handleFocus = this.handleFocus.bind(this);
		this.handleBlur = this.handleBlur.bind(this);
		this.handleSearch = this.handleSearch.bind(this);
        this.clearSearchTerm = this.clearSearchTerm.bind(this);

        this.state.allBrands = [];
        for(var i = 0; i < this.props.widgetData.groups.length; i++){
            this.props.widgetData.groups[i].brands.forEach(element => {
                this.state.allBrands.push(element);
            });
        }
	}

	handleFocus () {
		this.setState({ brandSearchActive: true });
	}

    handleBlur (event) {
		if (this.state.brandSearchActive && !(event?.target?.id == 'searchBrand')) {
			this.setState({ brandSearchActive: false });
		}
	}

    clearSearchTerm (event) {

		this.setState({
            showClear: false
        });
		this.setState({
			searchTerm: ''
		});
        this.state.brandsToUse = [];
	}

    scrollToBrand = (e) => {
        var headerOffset = 0;
        $('.desktopPage').length > 0 ? headerOffset = 115 : headerOffset = 0;

        var selectedLetter = e,
            selectedBrand = "#alphabet-" + selectedLetter;

            if (selectedLetter == '#') {
                $('html, body').animate({
                    scrollTop: $('#alphabet-number').offset().top - headerOffset
                }, 500);
            } else {
               $('html, body').animate({
                    scrollTop: $(selectedBrand).offset().top - headerOffset 
                }, 500); 
            }
    }
	handleSearch(event) {
		var newSearchTerm = event.target.value;
		//filter brands based on search term
		this.state.brandsToUse = [];
		for (var i = 0; i < this.state.allBrands.length; i++) {
			var thisBrand = this.state.allBrands[i];
			if (thisBrand.name && thisBrand.name.replace(/\s/g, '').toLowerCase().indexOf(newSearchTerm.replace(/\s/g, '').toLowerCase()) == 0) {
				this.state.brandsToUse.push(thisBrand);
                if(this.state.brandsToUse.length >= 20){
                    break;
                }
			}
		}
		this.setState({ searchTerm: newSearchTerm });
        this.setState({showClear: true});
	}

    render() {
        var ldJSON = {"@context":"http://schema.org","@type":"WebPage","url":"https://www.dillards.com/c/shopbybrand"};
        ldJSON.mainEntity = {"@type":"OfferCatalog","name":"Brands","itemListElement":[]};
    
        for(var i = 0; i < this.props.widgetData.groups.length; i++){
            var currentBrand = this.props.widgetData.groups[i].brands;
            for(let x = 0; x < currentBrand.length; x++){
                var brandJSONld = {"@type":"Brand","name":currentBrand[x].name,"url":"www.dillards.com"+currentBrand[x].link};
                ldJSON.mainEntity.itemListElement.push(brandJSONld);
            }
        }      
 
        return (
            <div>
            <script type="application/ld+json"
                suppressHydrationWarning={true} 
                dangerouslySetInnerHTML={{__html: JSON.stringify(
                {
                    "@context": "http://schema.org",
                    "@type": "BreadcrumbList",
                    "itemListElement":
                    [
                        {
                        "@type": "ListItem",
                        "position": 1,
                        "item":
                            {
                            "@id": "https://www.dillards.com/",
                            "name": "Home"
                            }
                        },
                        {
                        "@type": "ListItem",
                        "position": 2,
                        "item":
                            {
                            "@id": "https://www.dillards.com/c/shopbybrand",
                            "name": "Shop By Brands"
                            }
                        }
                    ]
                }
            )}}>
            </script>
            <script type="application/ld+json" 
                suppressHydrationWarning={true} 
        	    dangerouslySetInnerHTML={{__html: JSON.stringify(ldJSON)}}>
            </script>
            <div data-widgetname='ShopByBrand' className='shopByBrand col-sm-12' id='shopByBrand'>
                 <h1 className='text-center'>Shop By Brand</h1>
                 <div id='searchBrandInputArea'>
                    <input type="text" 
                    id="searchBrand" 
                    title="brand search box" 
                    autoComplete="off" 
                    autoCorrect="off" 
                    spellCheck="false"  
                    placeholder="Search Brands" 
                    value={this.state.searchTerm}
                    onChange={this.handleSearch}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}/> 
	                {this.state.showClear && this.state.searchTerm.length > 0 ?
							<button type="button" id='clearBrandSearch' onClick={this.clearSearchTerm} ><i><svg className="clearBtn" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48.77 48.77"><title>close</title><g data-name="Layer 2"><g data-name="Layer 1"><path d="M25.8,24.39,48.48,1.71A1,1,0,0,0,47.07.29L24.39,23,1.71.29A1,1,0,0,0,.29,1.71L23,24.39.29,47.07a1,1,0,1,0,1.41,1.41L24.39,25.8,47.07,48.48a1,1,0,0,0,1.41-1.41Z" /></g></g></svg></i></button>
							: <SearchIcon/>
					}
                </div>
                    <div id="brandSearchResults" className={this.state.searchTerm.length < 1 || !this.state.brandSearchActive ? ' hideEmpty' : 'col-sm-12'}>
                        <ul>
                            <li className={this.state.brandsToUse.length > 0 ? 'hidden': ''}>No results found</li>
                            <ForEach var='thisBrand' items={this.state.brandsToUse}>
                                <li key={thisBrand.link}>
                                    <Link to={thisBrand.link}>
                                    {thisBrand.name.toLowerCase().indexOf(this.state.searchTerm.toLowerCase()) > -1 && this.state.searchTerm.length >= 1 ? (
                                            thisBrand.name.toLowerCase().indexOf(this.state.searchTerm.toLowerCase()) > 0 ? (
                                                <span>
                                                    {thisBrand.name.substring(0, thisBrand.name.toLowerCase().indexOf(this.state.searchTerm.toLowerCase()))}
                                                    <strong>
                                                        {thisBrand.name.substring(
                                                            thisBrand.name.toLowerCase().indexOf(this.state.searchTerm.toLowerCase()),
                                                            thisBrand.name.toLowerCase().indexOf(this.state.searchTerm.toLowerCase()) + this.state.searchTerm.length
                                                        )}
                                                    </strong>
                                                    {thisBrand.name.substring(
                                                        thisBrand.name.toLowerCase().indexOf(this.state.searchTerm.toLowerCase()) + this.state.searchTerm.length,
                                                        thisBrand.name.length
                                                    )}
                                                </span>
                                            ) : (
                                                <span>
                                                    <strong>
                                                        {thisBrand.name.substring(thisBrand.name.toLowerCase().indexOf(this.state.searchTerm.toLowerCase()), this.state.searchTerm.length)}
                                                    </strong>
                                                    {thisBrand.name.substring(this.state.searchTerm.length, thisBrand.name.length)}
                                                </span>
                                            )
                                        ) : (
                                            <span>{thisBrand.name}</span>
                                        )}
                                    </Link>
                                </li>
                            </ForEach>
                        </ul>
                    
                    </div>

                <div className='col-sm-12 alphabetWrapper'>
                    <ul className='alphabet'>
                    {
                        this.props.widgetData.groups.map((thisGroup, groupIndex)=>
                            <li className='letter' key={thisGroup.alpha}>
                                <a href={`#alphabet-${thisGroup.alpha == '#' ? 'number' : thisGroup.alpha}`} onClick={() => this.scrollToBrand(thisGroup.alpha)}>{thisGroup.alpha}</a>
                            </li>
                        )
                    }
                    </ul>
                </div>
                {
                    this.props.widgetData.groups.map((thisGroup, groupIndex)=>
                        <div className='row brandDivider__wrapper' key={thisGroup.alpha}>
                            <div className='col-sm-12 brandDivider'>
                                <h2 id={`alphabet-${thisGroup.alpha == '#' ? 'number' : thisGroup.alpha}`}>{thisGroup.alpha}</h2>
                            </div>
                            {this.props.passedAppFunctions.siteFormat == 'tablet' ?
                                <EasyListSplitter columns={4} columnClassName='split-list'>
                                    {
                                        thisGroup.brands.map((thisBrand, brandIndex)=>
                                            <li key={thisBrand.name} >
                                                <Link to={thisBrand.link} >{thisBrand.name}</Link>
                                            </li>
                                        )
                                    }
                                </EasyListSplitter>
                            :
                                <EasyListSplitter columns={5} columnClassName='split-list'>
                                    {
                                        thisGroup.brands.map((thisBrand, brandIndex)=>
                                            <li key={thisBrand.name} >
                                                <Link to={thisBrand.link} >{thisBrand.name}</Link>
                                              </li>
                                        )
                                    }
                                </EasyListSplitter>
                            }
                         </div>
                    )
                }
            </div>  
            </div>
        );
    }
}



export default ShopByBrand;
